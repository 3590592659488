import React from "react";
import { IControlIcon } from "../../utils/types";

function GrillBig(props: IControlIcon) {
  return (
    <svg width="68" height="71" viewBox="0 0 68 71" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="35.4627" cy="27.9536" rx="28.0847" ry="5.71703" stroke="black" strokeWidth="3" />
      <circle cx="34.7035" cy="53.8024" r="7.59047" stroke="black" strokeWidth="3" />
      <path
        d="M7.37793 37.343V41.3638C7.37793 56.8746 19.9519 69.4485 35.4627 69.4485V69.4485C50.9734 69.4485 63.5474 56.8746 63.5474 41.3638V37.343"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M20.7852 9.77783L19.9576 10.9325C19.6271 11.3898 19.4761 11.9637 19.5357 12.5352C19.5953 13.1067 19.8611 13.6321 20.2779 14.0026C20.6968 14.3711 20.9651 14.8954 21.0272 15.467C21.0894 16.0386 20.9405 16.6137 20.6116 17.0726L20.3847 17.3683"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.6284 9.77783L48.8008 10.9325C48.4704 11.3898 48.3193 11.9637 48.379 12.5352C48.4386 13.1067 48.7044 13.6321 49.1212 14.0026C49.54 14.3711 49.8083 14.8954 49.8705 15.467C49.9326 16.0386 49.7838 16.6137 49.4549 17.0726L49.228 17.3683"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.3964 2.18726L34.1099 4.44428C33.197 5.33803 32.7796 6.45983 32.9444 7.57696C33.1092 8.69409 33.8434 9.72109 34.9949 10.4452C36.1521 11.1655 36.8934 12.1904 37.065 13.3077C37.2367 14.425 36.8256 15.549 35.9169 16.4461L35.29 17.0241"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default GrillBig;
