import React, { useEffect, useState, useContext, useCallback } from "react";
import { debounce } from "lodash";
import { sendHomeAssistantRequest } from "./utils/api";
import { CubeContext } from "./service/mttq-wrapper";
import {
  honeyCombControlsHome,
  items,
  sideLength,
  defaultPage,
  initialLightState,
  initialVentillatorState,
  initialWaterState,
  initialStoveState,
  initialFridgeState,
  initialGrillState,
  colors,
} from "./utils/config";
import { ILightState, IVentillatorState, IWaterState, IStoveState, IFridgeState, IGrillState } from "./utils/types";

import Header from "./components/header";
import Honeycomb from "./components/honeycomb/honeycomb";
import Hexagon from "./components/honeycomb/hexagon";
import HexagonContent from "./components/honeycomb/hexagon-content";

import LightController from "./components/subpage/light-controller";
import VentillatorController from "./components/subpage/ventillator-controller";
import GrillController from "./components/subpage/grill-container";
import WaterController from "./components/subpage/water-controller";
import FridgeController from "./components/subpage/fridge-controller";
import StoveController from "./components/subpage/stove-controller";

import DataBox from "./components/icons/data-box";
// import Wind from "./components/icons/wind";

import Kitchen from "./assets/konyha.png";
import "./assets/css/style.scss";
import LightSelected from "./components/icons/sm/light-selected";
import LightActive from "./components/icons/sm/light-active";
import LightNormal from "./components/icons/sm/light-normal";
import VentillatorSelected from "./components/icons/sm/ventillator-selected";
import VentillatorActive from "./components/icons/sm/ventillator-active";
import VentillatorNormal from "./components/icons/sm/ventillator-normal";
import StoveSelected from "./components/icons/sm/stove-selected";
import StoveNormal from "./components/icons/sm/stove-normal";
import WaterActive from "./components/icons/sm/water-active";
import WaterSelected from "./components/icons/sm/water-selected";
import WaterNormal from "./components/icons/sm/water-normal";
import FridgeSelected from "./components/icons/sm/fridge-selected";
import FridgeActive from "./components/icons/sm/fridge-active";
import FridgeNormal from "./components/icons/sm/fridge-normal";
import GrillSelected from "./components/icons/sm/grill-selected";
import GrillActive from "./components/icons/sm/grill-active";
import GrillNormal from "./components/icons/sm/grill-normal";
import { EHomeAssistantActions, EHomeAssistantEndpoints, EHomeAssistantItems, EHomeAssistantTopic } from "./interfaces/home-assistant";

function App() {
  const { sensors } = useContext(CubeContext)
  console.log("sensors", sensors);

  const delayedApiCaller = useCallback(
      // eslint-disable-line
      debounce(async (path: string, data: {}) => {
          try {
              await sendHomeAssistantRequest(path, data)
          } catch (error) {
              console.log("Failed to call Home Assistant api", error)
          }
      }, 1000),
      []
  )

  const [activeRouter, setActiveRouter] = useState<string>(defaultPage);
  const [lightState, setLightState] = useState<ILightState>(initialLightState);
  const [ventillatorState, setVentillatorState] = useState<IVentillatorState>(initialVentillatorState);
  const [waterState, setWaterState] = useState<IWaterState>(initialWaterState);
  const [stoveState, setStoveState] = useState<IStoveState>(initialStoveState);
  const [fridgeState, setFridgeState] = useState<IFridgeState>({
      ...initialFridgeState,
      temperature1: Math.round(parseFloat(sensors[EHomeAssistantTopic.FRIDGE_1_TEMPERATURE])),
      humidity1: Math.round(parseFloat(sensors[EHomeAssistantTopic.FRIDGE_1_HUMIDITY])),
      temperature2: Math.round(parseFloat(sensors[EHomeAssistantTopic.FRIDGE_2_TEMPERATURE])),
      humidity2: Math.round(parseFloat(sensors[EHomeAssistantTopic.FRIDGE_2_HUMIDITY])),
  })
  const [grillState, setGrillState] = useState<IGrillState>(initialGrillState);
  const [isMusicOn, setIsMusicOn] = useState<boolean>(false);
  const [isDrawerOn, setIsDrawerOn] = useState<boolean>(false);
  

  useEffect(() => {
    setIsDrawerOn(sensors[EHomeAssistantTopic.DOOR_LEFT_WING] === "on" && sensors[EHomeAssistantTopic.DOOR_RIGHT_WING] === "on");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensors[EHomeAssistantTopic.DOOR_LEFT_WING], sensors[EHomeAssistantTopic.DOOR_RIGHT_WING]]);

  useEffect(() => {
      setFridgeState({
          ...fridgeState,
          temperature1: parseFloat(sensors[EHomeAssistantTopic.FRIDGE_1_TEMPERATURE]),
          temperature2: parseFloat(sensors[EHomeAssistantTopic.FRIDGE_2_TEMPERATURE]),
          humidity1: parseFloat(sensors[EHomeAssistantTopic.FRIDGE_1_HUMIDITY]),
          humidity2: parseFloat(sensors[EHomeAssistantTopic.FRIDGE_2_HUMIDITY]),
          isOn: sensors[EHomeAssistantTopic.FRIDGE_IS_ON] === "on",
      })

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
      sensors[EHomeAssistantTopic.FRIDGE_1_TEMPERATURE],
      sensors[EHomeAssistantTopic.FRIDGE_2_TEMPERATURE],
      sensors[EHomeAssistantTopic.FRIDGE_1_HUMIDITY],
      sensors[EHomeAssistantTopic.FRIDGE_2_HUMIDITY],
  ])

  useEffect(() => {
    const fanRightIsOn = sensors[EHomeAssistantTopic.FAN_RIGHT_STATE] === "on";
    const newState = ventillatorState;
    newState.ventillatorOne.isOn = fanRightIsOn;
    newState.ventillatorOne.speed = parseInt(sensors[EHomeAssistantTopic.FAN_RIGHT_IN_PERCENTAGE], 10);
    setVentillatorState(newState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensors[EHomeAssistantTopic.FAN_RIGHT_STATE], sensors[EHomeAssistantTopic.FAN_RIGHT_IN_PERCENTAGE]]);

  useEffect(() => {
    const fanLeftIsOn = sensors[EHomeAssistantTopic.FAN_LEFT_STATE] === "on";
    const newState = ventillatorState;
    newState.ventillatorTwo.isOn = fanLeftIsOn;
    newState.ventillatorTwo.speed = parseInt(sensors[EHomeAssistantTopic.FAN_LEFT_IN_PERCENTAGE], 10);
    setVentillatorState(newState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensors[EHomeAssistantTopic.FAN_LEFT_STATE], sensors[EHomeAssistantTopic.FAN_LEFT_IN_PERCENTAGE]]);

  useEffect(() => {
    const lightSecondaryIsOn = sensors[EHomeAssistantTopic.LIGHT_BACK_STATE] === "on";
    const newState = lightState;
    newState.lightSecondary.isOn = lightSecondaryIsOn;
    newState.lightSecondary.dim =  Math.round(sensors[EHomeAssistantTopic.LIGHT_BACK_BRIGHTNESS] / 255 * 100);
    
    setLightState(newState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EHomeAssistantTopic.LIGHT_BACK_STATE, sensors[EHomeAssistantTopic.LIGHT_BACK_BRIGHTNESS]]);

  useEffect(() => {
    const lightFrontIsOn = sensors[EHomeAssistantTopic.LIGHT_FRONT_STATE] === "on";
    const newState = lightState;
    newState.lightMain.isOn = lightFrontIsOn;
    newState.lightMain.dim = Math.round(sensors[EHomeAssistantTopic.LIGHT_FRONT_BRIGHTNESS] / 255 * 100);
    setLightState(newState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensors[EHomeAssistantTopic.LIGHT_FRONT_STATE], sensors[EHomeAssistantTopic.LIGHT_FRONT_BRIGHTNESS]]);

  useEffect(() => {
    const lightOutIsOn = sensors[EHomeAssistantTopic.LIGHT_OUTSIDE_STATE] === "on";
    const newState = lightState;
    newState.lightOutside.isOn = lightOutIsOn;
    setLightState(newState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensors[EHomeAssistantTopic.LIGHT_OUTSIDE_STATE]]);

  useEffect(() => {
    const stoveTemperatureBig = sensors[EHomeAssistantTopic.STOVE_BIG_STATE];
    const newState = stoveState;
    newState.temperatureBig = stoveTemperatureBig;
    setStoveState(newState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensors[EHomeAssistantTopic.STOVE_BIG_STATE]]);

  useEffect(() => {
    const newState = stoveState;
    newState.temperatureSmall = sensors[EHomeAssistantTopic.STOVE_SMALL_STATE];
    setStoveState(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensors[EHomeAssistantTopic.STOVE_SMALL_STATE]]);

  useEffect(() => {
      const newState = waterState
      newState.waterLevel = sensors[EHomeAssistantTopic.WATER_TANK_LEVEL]
      setWaterState(newState)
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensors[EHomeAssistantTopic.WATER_TANK_LEVEL]])

  /* Selection controllers above the image */
  const [isVentillatorOneSelected, setIsVentillatorOneSelected] = useState<boolean>(false);
  const [isVentillatorTwoSelected, setIsVentillatorTwoSelected] = useState<boolean>(false);
  const [isWaterSelected, setIsWaterSelected] = useState<boolean>(false);
  const [isStoveSelected, setIsStoveSelected] = useState<boolean>(false);
  const [isFridgeSelected, setIsFridgeSelected] = useState<boolean>(false);
  const [isGrillSelected, setIsGrillSelected] = useState<boolean>(false);
  const [isLightMainSelected, setIsLightMainSelected] = useState<boolean>(false);
  const [isLightSecondarySelected, setIsLightSecondarySelected] = useState<boolean>(false);
  const [isLightOutsideSelected, setIsLightOutsideSelected] = useState<boolean>(false);

  const lightMainSelectChange = () => {
    setIsLightMainSelected(!isLightMainSelected);
    setActiveRouter("light");
  };
  const lightSecondarySelectChange = () => {
    setIsLightSecondarySelected(!isLightSecondarySelected);
    setActiveRouter("light");
  };
  const lightOutsideSelectChange = () => {
    setIsLightOutsideSelected(!isLightOutsideSelected);
    setActiveRouter("light");
  };
  const ventillatorOneSelectChange = () => {
    setIsVentillatorOneSelected(!isVentillatorOneSelected);
    setActiveRouter("ventillator");
  };
  const ventillatorTwoSelectChange = () => {
    setIsVentillatorTwoSelected(!isVentillatorTwoSelected);
    setActiveRouter("ventillator");
  };
  const waterSelectChange = () => {
    setIsWaterSelected(!isWaterSelected);
    setActiveRouter("water");
  };
  const stoveSelectChange = () => {
    setIsStoveSelected(!isStoveSelected);
    setActiveRouter("stove");
  };
  const fridgeSelectChange = () => {
    setIsFridgeSelected(!isFridgeSelected);
    setActiveRouter("fridge");
  };
  const grillSelectChange = () => {
    setIsGrillSelected(!isGrillSelected);
    setActiveRouter("grill");
  };

  const activeRouterHandle = (slug: string) => {
    setActiveRouter(slug);
  };

  const lightStateChangeHandle = async (item: string, value?: number) => {
      try {
          const oldState: ILightState = lightState
          let action = ""
          switch (item) {
              case "main":
                  action = oldState.lightMain.isOn ? EHomeAssistantActions.TURN_OFF : EHomeAssistantActions.TURN_ON
                  await delayedApiCaller(`/${EHomeAssistantEndpoints.LIGHT}/${action}`, { entity_id: EHomeAssistantItems.LIGHT_FRONT })
                  oldState.lightMain.isOn = !oldState.lightMain.isOn
                  setIsLightMainSelected(false)

                  break
              case "secondary":
                  action = oldState.lightSecondary.isOn ? EHomeAssistantActions.TURN_OFF : EHomeAssistantActions.TURN_ON
                  await delayedApiCaller(`/${EHomeAssistantEndpoints.LIGHT}/${action}`, { entity_id: EHomeAssistantItems.LIGHT_BACK })
                  oldState.lightSecondary.isOn = !oldState.lightSecondary.isOn
                  setIsLightSecondarySelected(false)
                  break
              case "outside":
                  action = oldState.lightOutside.isOn ? EHomeAssistantActions.TURN_OFF : EHomeAssistantActions.TURN_ON
                  await delayedApiCaller(`/${EHomeAssistantEndpoints.SWITCH}/${action}`, { entity_id: EHomeAssistantItems.LIGHT_OUTSIDE })
                  oldState.lightOutside.isOn = !oldState.lightOutside.isOn
                  setIsLightOutsideSelected(false)
                  break
              case "main-dim":
                  action = EHomeAssistantActions.TURN_ON
                  await delayedApiCaller(`/${EHomeAssistantEndpoints.LIGHT}/${action}`, { entity_id: EHomeAssistantItems.LIGHT_FRONT, brightness: Math.round(255 * (oldState.lightMain.dim / 100)) })
                  oldState.lightMain.dim = value || oldState.lightMain.dim
                  break
              case "secondary-dim":
                  action = EHomeAssistantActions.TURN_ON
                  await delayedApiCaller(`/${EHomeAssistantEndpoints.LIGHT}/${action}`, {
                      entity_id: EHomeAssistantItems.LIGHT_BACK,
                      brightness: Math.round(255 * (oldState.lightSecondary.dim / 100)),
                  })
                  oldState.lightSecondary.dim = value || oldState.lightSecondary.dim
                  break
              default:
                  break
          }

          setLightState({ ...oldState })
      } catch (error) {
          console.log("Failed to change the state of the item")
      }
  }

const ventillatorStateChangeHandle = async (item: string, speed?: number) => {
    try {
        const oldState: IVentillatorState = ventillatorState
        let action = ""
        switch (item) {
            case "1":
                action = oldState.ventillatorOne.isOn ? EHomeAssistantActions.TURN_OFF : EHomeAssistantActions.TURN_ON
                await delayedApiCaller(`/${EHomeAssistantEndpoints.FAN}/${action}`, { entity_id: EHomeAssistantItems.BBQUBE_FAN_RIGHT })
                oldState.ventillatorOne.isOn = !oldState.ventillatorOne.isOn
                setIsVentillatorOneSelected(false)
                break
            case "2":
                action = oldState.ventillatorTwo.isOn ? EHomeAssistantActions.TURN_OFF : EHomeAssistantActions.TURN_ON
                oldState.ventillatorTwo.isOn = !oldState.ventillatorTwo.isOn
                await delayedApiCaller(`/${EHomeAssistantEndpoints.FAN}/${action}`, { entity_id: EHomeAssistantItems.BBQUBE_FAN_LEFT })
                setIsVentillatorOneSelected(false)
                break
            case "speed1":
                action = EHomeAssistantActions.TURN_ON
                await delayedApiCaller(`/${EHomeAssistantEndpoints.FAN}/${action}`, { entity_id: EHomeAssistantItems.BBQUBE_FAN_RIGHT, percentage: speed || oldState.ventillatorOne.speed })
                oldState.ventillatorOne.speed = speed || oldState.ventillatorOne.speed
                break
            case "speed2":
                action = EHomeAssistantActions.TURN_ON
                await delayedApiCaller(`/${EHomeAssistantEndpoints.FAN}/${action}`, { entity_id: EHomeAssistantItems.BBQUBE_FAN_LEFT, percentage: speed || oldState.ventillatorTwo.speed })
                oldState.ventillatorTwo.speed = speed || oldState.ventillatorTwo.speed
                break
            default:
                break
        }
        setVentillatorState({ ...oldState })
    } catch (error) {
        console.log("Failed to change the state of the item")
    }
}

  const setDrawerStateHandle = async (entity_id: EHomeAssistantItems) => {
      try {
          await delayedApiCaller(EHomeAssistantEndpoints.DRAWER_BUTTON, { entity_id })
          switch (entity_id) {
              case EHomeAssistantItems.BUTTON_DRAWER_MOVE_IN:
                  setIsDrawerOn(false)
                  break
              case EHomeAssistantItems.BUTTON_DRAWER_MOVE_OUT:
                  setIsDrawerOn(true)
                  break
              case EHomeAssistantItems.BUTTON_DRAWER_RECOVERY:
                  setIsDrawerOn(true)
                  break

              default:
                  break
          }
      } catch (error) {
          console.log("Failed to change the state of the item")
      }
  }

  const waterStateChangeHandle = (item: string, value?: number) => {
    const oldState: IWaterState = waterState;

    switch (item) {
      case "toggle":
        oldState.isRunning = !oldState.isRunning;
        setIsWaterSelected(false);
        break;
      default:
        break;
    }

    setWaterState({ ...oldState });
  };

  const stoveStateChangeHandle = (item: string, value?: number) => {
    const oldState: IStoveState = stoveState;

    switch (item) {
      case "toggle":
        oldState.isOn = !oldState.isOn;
        setIsStoveSelected(false);
        break;
      case "temp":
        oldState.temperatureBig = value || oldState.temperatureBig;
        break;
      case "gas":
        oldState.temperatureSmall = value || oldState.temperatureSmall;
        break;
      default:
        break;
    }

    setStoveState({ ...oldState });
  };

  const fridgeStateChangeHandle = (item: string, temp?: number) => {
    const oldState: IFridgeState = fridgeState;

    switch (item) {
      case "toggle":
        oldState.isOn = !oldState.isOn;
        setIsFridgeSelected(false);
        break;
      case "temp":
        oldState.temperature1 = temp || oldState.temperature1;
        break;
      case "temp-goal":
        oldState.temperature2 = temp || oldState.temperature2;
        break;
      default:
        break;
    }

    setFridgeState({ ...oldState });
  };

  const grillStateChangeHandle = (item: string, temp?: number) => {
    const oldState: IGrillState = grillState;

    switch (item) {
      case "toggle":
        oldState.isOn = !oldState.isOn;
        setIsGrillSelected(false);
        break;
      case "temp-goal":
        oldState.temperatureGoal = temp || oldState.temperatureGoal;
        break;
      case "blow":
        oldState.blow = !oldState.blow;
        break;
      default:
        break;
    }

    setGrillState({ ...oldState });
  };

  useEffect(() => {
    if (ventillatorState.ventillatorOne.isOn) {
      ventillatorOneSelectChange();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ventillatorState]);

  useEffect(() => {
    if (ventillatorState.ventillatorTwo.isOn) {
      ventillatorTwoSelectChange();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ventillatorState]);

  useEffect(() => {
    if (lightState.lightMain.isOn) {
      lightMainSelectChange();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lightState]);

  useEffect(() => {
    if (lightState.lightSecondary.isOn) {
      lightSecondarySelectChange();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lightState]);

  useEffect(() => {
    if (lightState.lightOutside.isOn) {
      lightOutsideSelectChange();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lightState]);

  useEffect(() => {
    if (waterState.isRunning) {
      waterSelectChange();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterState]);

  useEffect(() => {
    if (stoveState.isOn) {
      stoveSelectChange();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stoveState]);

  useEffect(() => {
    if (fridgeState.isOn) {
      fridgeSelectChange();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fridgeState]);

  useEffect(() => {
    if (grillState.isOn) {
      grillSelectChange();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grillState]);

  /**
   * Very basic routing
   * Replaces honeycomb component when we want to change the settings for light, ventillation etc.
   *
   * @return [type]
   */
  function changePage() {
    switch (activeRouter) {
      case "light":
        return (
          <>
            <LightController
              activeRouterHandle={activeRouterHandle}
              lightState={lightState}
              setLightState={lightStateChangeHandle}
              mainLightState={isLightMainSelected}
              secondaryLightState={isLightSecondarySelected}
              outsideLightState={isLightOutsideSelected}
            />
          </>
        );
      case "ventillator":
        return (
          <VentillatorController
            isVentillatorOneSelected={isVentillatorOneSelected}
            isVentillatorTwoSelected={isVentillatorTwoSelected}
            activeRouterHandle={activeRouterHandle}
            ventillatorState={ventillatorState}
            setVentillatorState={ventillatorStateChangeHandle}
          />
        );
      case "grill":
        return (
          <GrillController
            isGrillSelected={isGrillSelected}
            activeRouterHandle={activeRouterHandle}
            grillState={grillState}
            setGrillState={grillStateChangeHandle}
            drawerState={isDrawerOn}
            setDrawerState={setDrawerStateHandle}
          />
        );
      case "fridge":
        return (
          <FridgeController
            isFridgeSelected={isFridgeSelected}
            activeRouterHandle={activeRouterHandle}
            fridgeState={fridgeState}
            setFridgeState={fridgeStateChangeHandle}
          />
        );
      case "stove":
        return (
          <StoveController isStoveSelected={isStoveSelected} activeRouterHandle={activeRouterHandle} stoveState={stoveState} setStoveState={stoveStateChangeHandle} />
        );
      case "water":
        return (
          <WaterController isWaterSelected={isWaterSelected} activeRouterHandle={activeRouterHandle} waterState={waterState} setWaterState={waterStateChangeHandle} />
        );

      default:
        return (
          <div className="honeycomb-container home">
            <Honeycomb
              className="honeycomb"
              columns={4}
              gap={0}
              size={sideLength}
              items={items}
              renderItem={(item: any, index: number) => {
                switch (honeyCombControlsHome[index]) {
                  case "light":
                    return (
                      <Hexagon
                        className={`hexagon-item ${lightState.lightMain.isOn || lightState.lightSecondary.isOn || lightState.lightOutside.isOn ? "light-active" : ""}`}>
                        <HexagonContent activeRouterHandle={activeRouterHandle} content={honeyCombControlsHome[index]} />
                      </Hexagon>
                    );
                  case "grill":
                    return (
                      <Hexagon className={`hexagon-item grill ${grillState.isOn ? "grill-active" : ""}`}>
                        <HexagonContent activeRouterHandle={activeRouterHandle} content={honeyCombControlsHome[index]} />
                      </Hexagon>
                    );
                  case "ventillator":
                    return (
                      <Hexagon className={`hexagon-item ${ventillatorState.ventillatorOne.isOn || ventillatorState.ventillatorTwo.isOn ? "ventillator-active" : ""}`}>
                        <HexagonContent activeRouterHandle={activeRouterHandle} content={honeyCombControlsHome[index]} />
                      </Hexagon>
                    );
                  case "fridge":
                    return (
                      <Hexagon className={`hexagon-item ${fridgeState.isOn ? "fridge-active" : ""}`}>
                        <HexagonContent activeRouterHandle={activeRouterHandle} content={honeyCombControlsHome[index]} />
                      </Hexagon>
                    );
                  case "stove":
                    return (
                      <Hexagon className={`hexagon-item ${stoveState.isOn ? "stove-active" : ""}`}>
                        <HexagonContent activeRouterHandle={activeRouterHandle} content={honeyCombControlsHome[index]} />
                      </Hexagon>
                    );
                  case "water":
                    return (
                      <Hexagon className="hexagon-item">
                        <HexagonContent activeRouterHandle={activeRouterHandle} content={honeyCombControlsHome[index]} />
                      </Hexagon>
                    );
                  case "music":
                    return (
                      <Hexagon className={`hexagon-item ${isMusicOn ? "music-active" : ""}`}>
                        <HexagonContent state={isMusicOn} setStateFunc={setIsMusicOn} content={honeyCombControlsHome[index]} />
                      </Hexagon>
                    );
                  case "goback":
                    return (
                      <Hexagon className={`hexagon-item ${isMusicOn ? "music-active" : ""}`}>
                        <HexagonContent activeRouterHandle={activeRouterHandle} content={honeyCombControlsHome[index]} />
                      </Hexagon>
                    );
                  case "drawer":
                    return (
                      <Hexagon className={`hexagon-item ${isDrawerOn ? "drawer-active" : ""}`}>
                        <HexagonContent
                          state={isDrawerOn}
                          setStateFunc={setIsDrawerOn}
                          activeRouterHandle={activeRouterHandle}
                          content={honeyCombControlsHome[index]}
                        />
                      </Hexagon>
                    );
                  default:
                    return (
                      <Hexagon className="hexagon-item">
                        <HexagonContent content={honeyCombControlsHome[index]} />
                      </Hexagon>
                    );
                }
              }}
            />
          </div>
        );
    }
  }

  const selectOrActivateVentillatorOne = () => {
    if (isVentillatorOneSelected && !ventillatorState.ventillatorOne.isOn) {
      return (
        <div className="icon ventillator-1 is-selected" onClick={() => ventillatorOneSelectChange()}>
          <VentillatorSelected />
        </div>
      );
    } else if (ventillatorState.ventillatorOne.isOn) {
      return (
        <div className="icon ventillator-1 is-active" onClick={() => ventillatorOneSelectChange()}>
          <VentillatorActive />
        </div>
      );
    } else {
      return (
        <div className="icon ventillator-1" onClick={() => ventillatorOneSelectChange()}>
          <VentillatorNormal />
        </div>
      );
    }
  };
  const selectOrActivateVentillatorTwo = () => {
    if (isVentillatorTwoSelected && !ventillatorState.ventillatorTwo.isOn) {
      return (
        <div className="icon ventillator-2 is-selected" onClick={() => ventillatorTwoSelectChange()}>
          <VentillatorSelected />
        </div>
      );
    } else if (ventillatorState.ventillatorTwo.isOn) {
      return (
        <div className="icon ventillator-2 is-active" onClick={() => ventillatorTwoSelectChange()}>
          <VentillatorActive />
        </div>
      );
    } else {
      return (
        <div className="icon ventillator-2" onClick={() => ventillatorTwoSelectChange()}>
          <VentillatorNormal />
        </div>
      );
    }
  };

  const selectOrActivateLightMain = () => {
    if (isLightMainSelected && !lightState.lightMain.isOn) {
      return (
        <div className={`icon light is-selected`} onClick={() => lightMainSelectChange()}>
          <LightSelected />
        </div>
      );
    } else if (lightState.lightMain.isOn) {
      return (
        <div className="icon light is-active" onClick={() => lightMainSelectChange()}>
          <LightActive />
        </div>
      );
    } else {
      return (
        <div className="icon light" onClick={() => lightMainSelectChange()}>
          <LightNormal />
        </div>
      );
    }
  };

  const selectOrActivateLightSecondary = () => {
    if (isLightSecondarySelected && !lightState.lightSecondary.isOn) {
      return (
        <div className={`icon light-secondary is-selected`} onClick={() => lightSecondarySelectChange()}>
          <LightSelected />
        </div>
      );
    } else if (lightState.lightSecondary.isOn) {
      return (
        <div className="icon light-secondary is-active" onClick={() => lightSecondarySelectChange()}>
          <LightActive />
        </div>
      );
    } else {
      return (
        <div className="icon light-secondary" onClick={() => lightSecondarySelectChange()}>
          <LightNormal />
        </div>
      );
    }
  };

  const selectOrActivateLightOutside = () => {
    if (isLightOutsideSelected && !lightState.lightOutside.isOn) {
      return (
        <div className={`icon light-outside is-selected`} onClick={() => lightOutsideSelectChange()}>
          <LightSelected />
        </div>
      );
    } else if (lightState.lightOutside.isOn) {
      return (
        <div className="icon light-outside is-active" onClick={() => lightOutsideSelectChange()}>
          <LightActive />
        </div>
      );
    } else {
      return (
        <div className="icon light-outside" onClick={() => lightOutsideSelectChange()}>
          <LightNormal />
        </div>
      );
    }
  };

  const selectOrActivateStove = () => {
    if (isStoveSelected && !stoveState.isOn) {
      return (
        <div className="icon stove is-selected" onClick={() => stoveSelectChange()}>
          <StoveSelected />
        </div>
      );
    } else if (stoveState.isOn) {
      return (
        <div className="icon stove is-active" onClick={() => stoveSelectChange()}>
          <StoveSelected />
        </div>
      );
    } else {
      return (
        <div className="icon stove is-selected" onClick={() => stoveSelectChange()}>
          <StoveNormal />
        </div>
      );
    }
  };

  const selectOrActivateWater = () => {
    if (isWaterSelected && !waterState.isRunning) {
      return (
        <div className="icon water is-selected" onClick={() => waterSelectChange()}>
          <WaterSelected />
        </div>
      );
    } else if (waterState.isRunning) {
      return (
        <div className="icon water is-active" onClick={() => waterSelectChange()}>
          <WaterActive />
        </div>
      );
    } else {
      return (
        <div className="icon water" onClick={() => waterSelectChange()}>
          <WaterNormal />
        </div>
      );
    }
  };

  const selectOrActivateFridge = () => {
    if (isFridgeSelected && !fridgeState.isOn) {
      return (
        <div className="icon fridge is-selected" onClick={() => fridgeSelectChange()}>
          <FridgeSelected />
        </div>
      );
    } else if (fridgeState.isOn) {
      return (
        <div className="icon fridge is-active" onClick={() => fridgeSelectChange()}>
          <FridgeActive />
        </div>
      );
    } else {
      return (
        <div className="icon fridge" onClick={() => fridgeSelectChange()}>
          <FridgeNormal />
        </div>
      );
    }
  };

  const selectOrActivateGrill = () => {
    if (isGrillSelected && !grillState.isOn) {
      return (
        <div className="icon grill is-selected" onClick={() => grillSelectChange()}>
          <GrillSelected />
        </div>
      );
    } else if (grillState.isOn) {
      return (
        <div className="icon grill is-active" onClick={() => grillSelectChange()}>
          <GrillActive />
        </div>
      );
    } else {
      return (
        <div className="icon grill" onClick={() => grillSelectChange()}>
          <GrillNormal />
        </div>
      );
    }
  };

  return (
    <div className="home-assistant-page">
      <Header activeRouter={activeRouter} activeRouterHandle={activeRouterHandle} />
      <main className="content">
        <div className="bg-container">
          <img className="kitchen" src={Kitchen} alt="" />
          {selectOrActivateVentillatorOne()}
          <div className={`icon ventillator-percentage ${ventillatorState.ventillatorOne.isOn ? "is-on" : "is-off"}`}>
            <DataBox color={colors.purple} />
            <div className="label">
              {ventillatorState.ventillatorOne.speed}
              {ventillatorState.ventillatorOne.speed === 100 ? <span className="unit d-block lh-1">%</span> : <span className="unit">%</span>}
            </div>
          </div>
          {selectOrActivateVentillatorTwo()}
          <div className={`icon ventillator-percentage-2 ${ventillatorState.ventillatorTwo.isOn ? "is-on" : "is-off"}`}>
            <DataBox color={colors.purple} />
            <div className="label">
              {ventillatorState.ventillatorTwo.speed}
              {ventillatorState.ventillatorTwo.speed === 100 ? <span className="unit d-block lh-1">%</span> : <span className="unit">%</span>}
            </div>
          </div>
          {selectOrActivateLightMain()}
          {selectOrActivateLightSecondary()}
          {selectOrActivateLightOutside()}
          <div className={`icon light-percentage ${lightState.lightMain.isOn ? "is-on" : "is-off"}`}>
            <DataBox color={colors.orange} />
            <div className="label">
              {lightState.lightMain.dim}
              {lightState.lightMain.dim === 100 ? <span className="unit d-block lh-1">%</span> : <span className="unit">%</span>}
            </div>
          </div>
          <div className={`icon light-percentage-2 ${lightState.lightSecondary.isOn ? "is-on" : "is-off"}`}>
            <DataBox color={colors.orange} />
            <div className="label">
              {lightState.lightSecondary.dim}
              {lightState.lightSecondary.dim === 100 ? <span className="unit d-block lh-1">%</span> : <span className="unit">%</span>}
            </div>
          </div>
          {/* <div className={`icon light-percentage-3 ${lightState.lightOutside.isOn ? "is-on" : "is-off"}`}>
            <DataBox color={colors.orange} />
          </div> */}
          {selectOrActivateWater()}
          <div className={`icon water-percentage ${waterState.isRunning ? "is-on" : "is-off"}`}>
            <DataBox color={colors.blue} />
            <div className="label">
              {waterState.waterLevel}
              {waterState.waterLevel === 100 ? <span className="unit d-block lh-1">%</span> : <span className="unit">%</span>}
            </div>
          </div>
          {selectOrActivateFridge()}
          <div className={`icon fridge-temp ${fridgeState.isOn ? "is-on" : "is-off"}`}>
            <DataBox color={colors.cyan} />
            <div className="label">
              {fridgeState.temperature1}
              {fridgeState.temperature1 >= 10 ? <span className="unit d-block lh-1">°C</span> : <span className="unit">°C</span>}
            </div>
          </div>
          <div className={`icon fridge-temp-goal ${fridgeState.isOn ? "is-on" : "is-off"}`}>
            <DataBox color={colors.cyan} />
            <div className="label">
              {fridgeState.temperature2}
              {fridgeState.temperature2 >= 10 ? <span className="unit d-block lh-1">°C</span> : <span className="unit">°C</span>}
            </div>
          </div>
          {selectOrActivateStove()}
          <div className={`icon stove-temp ${stoveState.isOn ? "is-on" : "is-off"}`}>
            <DataBox color={colors.red} />
            <div className="label">
              {stoveState.temperatureBig}
              {stoveState.temperatureBig >= 100 ? <span className="unit d-block lh-1">°C</span> : <span className="unit">°C</span>}
            </div>
          </div>
          <div className={`icon stove-gas ${stoveState.isOn ? "is-on" : "is-off"}`}>
            <DataBox color={colors.red} />
            <div className="label">
              {stoveState.temperatureSmall}
              {stoveState.temperatureSmall === 100 ? <span className="unit d-block lh-1">%</span> : <span className="unit">%</span>}
            </div>
          </div>
          {selectOrActivateGrill()}
          <div className={`icon grill-temp ${grillState.isOn ? "is-on" : "is-off"}`}>
            <DataBox color={colors.bordeaux} />
            <div className="label">
              {grillState.temperature}
              {grillState.temperature >= 100 ? <span className="unit d-block lh-1">°C</span> : <span className="unit">°C</span>}
            </div>
          </div>
          {isGrillSelected && !grillState.isOn && (
            <div className="grill-highlight">
              <svg width="226" height="324" viewBox="0 0 226 324" style={{ transform: "scale(0.87)"}} fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4 187L2.5 9.0001C48.3866 5.29012 100.5 2.49987 127.5 2.99999C154.5 3.50011 224 15.9999 224 15.9999V204C224 204 221.928 240.96 208 261.5C193.796 282.448 158 303 158 303V315.5C158 315.5 133.5 325.5 110.5 320.5C87.5 315.5 69 305.5 67 304.5C65 303.5 65.781 293.529 65 286.5L45 270.5C45 270.5 31.5917 258.681 24.5 249.5C8.79766 229.171 4 187 4 187Z"
                  stroke="#CA1B1B"
                  strokeWidth="4"
                />
              </svg>
            </div>
          )}
          {/*<div className={`icon grill-blow ${grillState.isOn && grillState.blow ? "is-on" : "is-off"}`}>
            <DataBox color={colors.bordeaux} />
            <div className="label">
              <Wind size={30} />
            </div>
          </div>*/}
        </div>
        {changePage()}
      </main>
    </div>
  );
}

export default App;
