import React from "react";
import { honeyCombControlsStove, items, sideLength } from "../../utils/config";
import { IStoveState, IStoveController } from "../../utils/types";

import Honeycomb from "../honeycomb/honeycomb";
import Hexagon from "../honeycomb/hexagon";
import HexagonContent from "../honeycomb/hexagon-content";

const StoveController = (props: IStoveController) => {
  const activeRouterHandle = props.activeRouterHandle;
  const stoveState: IStoveState = props.stoveState;
  const setStoveIsOnStateHandle: Function = () => {
    props.setStoveState("toggle");
  };
    const setStoveTemperatureHandle: Function = (value: number) => {
      props.setStoveState("temp", value);
    };

  return (
    <div className="honeycomb-container stove">
      <Honeycomb
        className="honeycomb"
        columns={4}
        gap={0}
        size={sideLength}
        items={items}
        renderItem={(item: any, index: number) => {
          switch (honeyCombControlsStove[index]) {
            case "stove-page":
              return (
                <Hexagon className={`hexagon-item stove-active`}>
                  <HexagonContent content={honeyCombControlsStove[index]} />
                </Hexagon>
              );
            case "stove-temperature":
              return (
                <Hexagon className={`hexagon-item ${stoveState.temperatureBig > 40 ? "stove-active" : ""}`}>
                  <HexagonContent
                    isSelected={props.isStoveSelected}
                    level={stoveState.temperatureBig}
                    content={honeyCombControlsStove[index]}
                    state={stoveState}
                    setStateFunc={setStoveIsOnStateHandle}
                    setStateValueFunc={setStoveTemperatureHandle}
                  />
                </Hexagon>
              );
            case "stove-gas":
              return (
                <Hexagon className={`hexagon-item ${stoveState.temperatureSmall > 40 ? "stove-active" : ""}`}>
                  <HexagonContent
                    isSelected={props.isStoveSelected}
                    level={stoveState.temperatureSmall}
                    content={honeyCombControlsStove[index]}
                    state={stoveState}
                    setStateFunc={setStoveIsOnStateHandle}
                    setStateValueFunc={setStoveTemperatureHandle}
                  />
                </Hexagon>
              );
            case "goback":
              return (
                <Hexagon className={`hexagon-item`}>
                  <HexagonContent activeRouterHandle={activeRouterHandle} content={honeyCombControlsStove[index]} />
                </Hexagon>
              );
            default:
              return (
                <Hexagon className="hexagon-item">
                  <HexagonContent content={honeyCombControlsStove[index]} />
                </Hexagon>
              );
          }
        }}
      />
    </div>
  );
};

export default StoveController;
