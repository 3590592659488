import React, { useState, useEffect } from "react";
import { IHexagonContent } from "../../utils/types";
import { colors, defaultPage, maxTemp, maxTempForFridge, minTempForFridge } from "../../utils/config";
import RangeSlider, { RangeSliderPosition, RangeSliderProps } from "@gilbarbara/react-range-slider";

import LightBig from "../icons/light-big";
import FridgeBig from "../icons/fridge-big";
import GrillBig from "../icons/grill-big";
import StoveBig from "../icons/stove-big";
import VentillatorBig from "../icons/ventillator-big";
import WaterBig from "../icons/water-big";
import ActiveIcon from "../icons/active-icon";
import BackArrow from "../icons/back-arrow";
import MusicBig from "../icons/music-big";

import Bg from "../../assets/bg.png";
import BgActive from "../../assets/active-bg.png";
import Wind from "../icons/wind";
import OpenIcon from "../icons/open-icon";
import CloseIcon from "../icons/close-icon";

import useLongPress from "../../utils/useLongPress";




function HexagonContent(props: IHexagonContent) {
  const { content, level, state } = props;
  const [y, setY] = useState<number>(0);

  const handleChange = (position: RangeSliderPosition) => {
    setY(position.y);

    if (content === "grill-goal" || content === "stove-temperature") {
      // max is 300 °C
      setStateValueFunc(position.y * 3);
    } else {
      setStateValueFunc(position.y);
    }
  };

  useEffect(() => {
    switch (content) {
      case "light-main":
        setY(state.lightMain.dim);
        break;
      case "light-secondary":
        setY(state.lightSecondary.dim);
        break;
      case "ventillator-one":
        setY(state.ventillatorOne.speed);
        break;
      case "ventillator-two":
        setY(state.ventillatorTwo.speed);
        break;
      case "water-level":
        setY(state.waterLevel);
        break;
      case "grill-temperature":
        setY(state.temperature);
        break;
      case "grill-goal":
        setY(state.temperatureGoal);
        break;
      case "fridge-temperature-2":
        setY(state.temperatureGoal);
        break;
      case "stove-temperature":
        setY(state.temperature);
        break;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setStateFunc = props.setStateFunc
    ? () => {
        props.setStateFunc && props.setStateFunc();
      }
    : () => {};

  const handleSliderPressEvent = useLongPress({onLongPress: () => {}, onClick: setStateFunc})

  const setStateValueFunc: Function = props.setStateValueFunc ? props.setStateValueFunc : () => {};

  const setLevel = () => (level ? level : 100);
  const percentageOfMaximum = (temp: number): number => Math.ceil((temp / maxTemp) * 100);
  const setTemperatureScale = (temp: number) => (percentageOfMaximum(temp) ? percentageOfMaximum(temp) : 0);
  const setCoolingScale = (temp: number) => (temp / (maxTempForFridge - minTempForFridge)) * 100;

  const activeRouterHandle = props.activeRouterHandle ? props.activeRouterHandle : () => {};
  const image = props.isSelected ? <img className="bg-image" src={BgActive} alt="" /> : <img className="bg-image" src={Bg} alt="" />;

  const goBackToHome = () => {
    activeRouterHandle(defaultPage);
  };

  const goBackToPage = (page: string) => {
    activeRouterHandle(page);
  };

  switch (content) {
    case "light":
      return (
        <div
          className="hexagon-content-container"
          onClick={() => {
            goBackToPage("light");
          }}>
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.orange} id={content} level={setLevel()}></ActiveIcon>
            </div>
            <LightBig></LightBig>
            <h2>Világítás</h2>
          </div>
          {image}
        </div>
      );
    case "grill":
      return (
        <div
          className="hexagon-content-container"
          style={{ zIndex: 103 }}
          onClick={() => {
            goBackToPage("grill");
          }}>
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.bordeaux} id={content} level={setLevel()}></ActiveIcon>
            </div>
            <GrillBig></GrillBig>
            <h2>Grill</h2>
          </div>
          {image}
        </div>
      );
    case "ventillator":
      return (
        <div
          className="hexagon-content-container"
          onClick={() => {
            goBackToPage("ventillator");
          }}>
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.purple} id={content} level={100}></ActiveIcon>
            </div>
            <VentillatorBig></VentillatorBig>
            <h2>Ventillátor</h2>
          </div>
          {image}
        </div>
      );
    case "fridge":
      return (
        <div
          className="hexagon-content-container"
          onClick={() => {
            goBackToPage("fridge");
          }}>
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.cyan} id={content} level={setLevel()}></ActiveIcon>
            </div>
            <FridgeBig></FridgeBig>
            <h2>Hűtő</h2>
          </div>
          {image}
        </div>
      );
    case "stove":
      return (
        <div
          className="hexagon-content-container"
          onClick={() => {
            goBackToPage("stove");
          }}>
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.red} id={content} level={setLevel()}></ActiveIcon>
            </div>
            <StoveBig></StoveBig>
            <h2>Főzőlap</h2>
          </div>
          {image}
        </div>
      );
    case "water":
      return (
        <div
          className="hexagon-content-container"
          onClick={() => {
            goBackToPage("water");
          }}>
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.blue} id={content} level={setLevel()}></ActiveIcon>
            </div>
            <WaterBig></WaterBig>
            <h2>Víz</h2>
          </div>
          {image}
        </div>
      );
    case "music":
      return (
        <div className="hexagon-content-container" onClick={() => props.setStateFunc && props.setStateFunc(!props.state)}>
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.green} id={content} level={setLevel()}></ActiveIcon>
            </div>
            <MusicBig></MusicBig>
            <h2>Zene</h2>
          </div>
          {image}
        </div>
      );
    case "drawer":
      return (
        <div className="hexagon-content-container" onClick={() => props.setStateFunc && props.setStateFunc(!props.state)}>
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.yellow} id={content} level={setLevel()}></ActiveIcon>
            </div>
            <OpenIcon></OpenIcon>
            <h2>Fiók</h2>
          </div>
          {image}
        </div>
      );
      case "drawer-in":
        return (
          <div className="hexagon-content-container" onClick={() => props.setStateFunc && props.setStateFunc(!props.state)}>
            <div className="content">
              <div className="active">
                <ActiveIcon color={colors.bordeaux} id={content} level={setLevel()}></ActiveIcon>
              </div>
              <CloseIcon></CloseIcon>
              <h2>Fiók</h2>
            </div>
            {image}
          </div>
        );
        case "drawer-recovery":
          return (
            <div className="hexagon-content-container" onClick={() => props.setStateFunc && props.setStateFunc(!props.state)}>
              <div className="content">
              </div>
              {image}
            </div>
          );

    case "goback":
      return (
        <div className="hexagon-content-container" style={{ cursor: "default" }}>
          <div
            className="content back-arrow"
            onClick={() => {
              goBackToHome();
            }}>
            <BackArrow color={"rgba(0,0,0,0.3)"}></BackArrow>
            <h2>Vissza</h2>
          </div>
          {image}
        </div>
      );

    case "light-page":
      return (
        <div className="hexagon-content-container">
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.orange} id={content} level={setLevel()}></ActiveIcon>
            </div>
            <LightBig></LightBig>
            <h2>Világítás</h2>
          </div>
          {image}
        </div>
      );
    case "light-main":
      return (
        <div className="hexagon-content-container">
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.orange} id={content} level={state.lightMain.dim}></ActiveIcon>
            </div>
            <div className="text-state">{state.lightMain.dim ? `${state.lightMain.dim}%` : "-"}</div>
            <h2>Fő</h2>
          </div>
          {image}
          <RangeSlider xMin={0} {...handleSliderPressEvent} className="ranger" axis="y" y={y} onChange={handleChange} />
        </div>
      );

    case "light-secondary":
      return (
        <div className="hexagon-content-container">
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.orange} id={content} level={state.lightSecondary.dim }></ActiveIcon>
            </div>
            <div className="text-state">{state.lightSecondary.dim ? `${state.lightSecondary.dim }%` : "-"}</div>
            <h2>Háttér</h2>
          </div>
          {image}
          <RangeSlider xMin={0} {...handleSliderPressEvent} className="ranger" axis="y" y={y} onChange={handleChange} />
        </div>
      );

    case "light-outside":
      return (
        <div className="hexagon-content-container">
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.orange} id={content} level={state.lightOutside.dim}></ActiveIcon>
            </div>
            <h2>Kinti</h2>
            <div className="text-state"></div>
          </div>
          {image}
          <RangeSlider xMin={0} {...handleSliderPressEvent} className="ranger" axis="y" y={y} onChange={handleChange} />
        </div>
      );

    case "ventillator-page":
      return (
        <div className="hexagon-content-container">
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.purple} id={content} level={setLevel()}></ActiveIcon>
            </div>
            <VentillatorBig></VentillatorBig>
            <h2>Ventillátor</h2>
          </div>
          {image}
        </div>
      );
    case "ventillator-one":
      return (
        <div className="hexagon-content-container" >
          
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.purple} id={content} level={state.ventillatorOne.speed}></ActiveIcon>
            </div>
            <div className="text-state">{state.ventillatorOne.speed ? `${state.ventillatorOne.speed}%` : "-"}</div>
            <h2>Jobb</h2>
          </div>
          {image}
          <RangeSlider xMin={0} {...handleSliderPressEvent} className="ranger" axis="y" y={y} onChange={(position) => {
              if (position.y >= 25) {
                handleChange(position)
              }
            }} />
        </div>
      );

    case "ventillator-two":
      return (
        <div className="hexagon-content-container">
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.purple} id={content} level={state.ventillatorTwo.speed}></ActiveIcon>
            </div>
            <div className="text-state">{state.ventillatorTwo.speed ? `${state.ventillatorTwo.speed}%` : "-"}</div>
            <h2>Bal</h2>
          </div>
          {image}
          <RangeSlider xMin={25} {...handleSliderPressEvent} className="ranger" axis="y" y={y} onChange={(position) => {
              if (position.y >= 25) {
                handleChange(position)
              }
            }} />
        </div>
      );

    case "water-page":
      return (
        <div className="hexagon-content-container">
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.blue} id={content} level={setLevel()}></ActiveIcon>
            </div>
            <WaterBig></WaterBig>
            <h2>Víz</h2>
          </div>
          {image}
        </div>
      );
    case "water-level":
      return (
        <div className="hexagon-content-container">
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.blue} id={content} level={state.waterLevel}></ActiveIcon>
            </div>
            <div className="text-state">{state.waterLevel ? `${state.waterLevel} %` : "-"}</div>
            <h2>Készlet</h2>
          </div>
          {image}
        </div>
      );

    case "stove-page":
      return (
        <div className="hexagon-content-container">
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.red} id={content} level={setLevel()}></ActiveIcon>
            </div>
            <StoveBig></StoveBig>
            <h2>Főzőlap</h2>
          </div>
          {image}
        </div>
      );

    case "stove-temperature":
      return (
        <div className="hexagon-content-container">
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.red} id={content} level={setTemperatureScale(state.temperature)}></ActiveIcon>
            </div>
            <div className="text-state">{state.temperatureBig ? `${state.temperatureBig}°C` : "-"}</div>
            <h2>Nagy főzőlap</h2>
          </div>
          {image}
        </div>
      );

    case "stove-gas":
      return (
        <div className="hexagon-content-container">
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.red} id={content} level={state.temperatureSmall}></ActiveIcon>
            </div>
            <div className="text-state">{state.temperatureSmall ? `${state.temperatureSmall}°C` : "-"}</div>
            <h2>Kis főzőlap</h2>
          </div>
          {image}
        </div>
      );

    case "fridge-page":
      return (
        <div className="hexagon-content-container">
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.cyan} id={content} level={setLevel()}></ActiveIcon>
            </div>
            <FridgeBig></FridgeBig>
            <h2>Hűtő</h2>
          </div>
          {image}
        </div>
      );

    case "fridge-temperature-1":
      return (
        <div className="hexagon-content-container">
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.cyan} id={content} level={setCoolingScale(state.temperature1)}></ActiveIcon>
            </div>
            <div className="text-state">{state.temperature1 ? `${state.temperature1} °C` : "-"}</div>
            <h2 style={{ fontSize: "1rem" }}>Hőmérséklet 1</h2>
          </div>
          {image}
        </div>
      );

      case "fridge-humidity-1":
        return (
          <div className="hexagon-content-container">
            <div className="content">
              <div className="active">
                <ActiveIcon color={colors.cyan} id={content} level={setCoolingScale(state.humidity1)}></ActiveIcon>
              </div>
              <div className="text-state">{state.humidity1 ? `${state.humidity1} %` : "-"}</div>
              <h2 style={{ fontSize: "1rem" }}>Páratartalom 1</h2>
            </div>
            {image}
          </div>
        );

    case "fridge-temperature-2":
      const rangeFridgeGoal = () => {
        return state.isOn ? (
          <RangeSlider yMin={minTempForFridge} yMax={maxTempForFridge} className="ranger" axis="y" y={y} onChange={handleChange} />
        ) : (
          <></>
        );
      };
      return (
        <div className="hexagon-content-container">
          {rangeFridgeGoal()}
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.cyan} id={content} level={setCoolingScale(state.temperatureGoal)}></ActiveIcon>
            </div>
            <div className="text-state">{state.temperature2 ? `${state.temperature2} °C` : "-"}</div>
            <h2 style={{ fontSize: "1rem" }}>Hőmérséklet 2</h2>
          </div>
          {image}
        </div>
      );

      case "fridge-humidity-2":
        return (
          <div className="hexagon-content-container">
            <div className="content">
              <div className="active">
                <ActiveIcon color={colors.cyan} id={content} level={setCoolingScale(state.humidity2)}></ActiveIcon>
              </div>
              <div className="text-state">{state.humidity2 ? `${state.humidity2} %` : "-"}</div>
              <h2 style={{ fontSize: "1rem" }}>Páratartalom 2</h2>
            </div>
            {image}
          </div>
        );

    case "grill-page":
      return (
        <div className="hexagon-content-container">
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.bordeaux} id={content} level={setLevel()}></ActiveIcon>
            </div>
            <GrillBig></GrillBig>
            <h2>Grillsütő</h2>
          </div>
          {image}
        </div>
      );

    case "grill-temperature":
      return (
        <div className="hexagon-content-container" onClick={() => setStateFunc()}>
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.bordeaux} id={content} level={setTemperatureScale(state.temperature)}></ActiveIcon>
            </div>
            <div className="text-state">{state.temperature ? `${state.temperature} °C` : "-"}</div>
            <h2>Hőmérséklet</h2>
          </div>
          {image}
        </div>
      );

    case "grill-goal":
      const rangeGrill = () => {
        return state.isOn ? <RangeSlider xMin={0} onClick={(e) => e.stopPropagation()} className="ranger" axis="y" y={y} onChange={handleChange} /> : <></>;
      };
      return (
        <div className="hexagon-content-container" onClick={() => setStateFunc()}>
          {rangeGrill()}
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.bordeaux} id={content} level={setTemperatureScale(state.temperatureGoal)}></ActiveIcon>
            </div>
            <div className="text-state">{`${state.temperatureGoal} °C`}</div>
            <h2 style={{ fontSize: "1.1rem" }}>Célhőmérséklet</h2>
          </div>
          {image}
        </div>
      );

    case "grill-blow":
      return (
        <div className="hexagon-content-container" onClick={() => setStateFunc()}>
          <div className="content">
            <div className="active">
              <ActiveIcon color={colors.bordeaux} id={content} level={setLevel()}></ActiveIcon>
            </div>
            <Wind></Wind>
            <h2>Befúvás</h2>
          </div>
          {image}
        </div>
      );

    default:
      return <>{image}</>;
  }
}

export default HexagonContent;
