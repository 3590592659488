import React from "react";
import { IControlIcon } from "../../utils/types";

function FridgeBig(props: IControlIcon) {
  return (
    <svg width="69" height="72" viewBox="0 0 69 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.5905 2.41724V24.8376" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M45.8007 6.90137L34.5905 15.8695L23.3802 6.90137" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.47388 19.2471L24.875 30.4573" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.9499 11.7737L17.1023 25.9733L3.73975 31.2047" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.47388 52.8777L24.875 41.6675" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.73975 40.9202L17.1023 46.1516L14.9499 60.3512" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M34.5905 69.6785V47.2581" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M23.3802 65.1945L34.5905 56.2263L45.8007 65.1945" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M63.707 52.8777L44.3059 41.6675" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M54.2302 60.3512L52.0779 46.1516L65.4404 40.9202" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M63.707 19.2471L44.3059 30.4573" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M65.4404 31.2047L52.0779 25.9733L54.2302 11.7737" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M34.5904 24.8376L24.8749 30.4282V41.6675L34.5904 47.258L44.3059 41.6675V30.4282L34.5904 24.8376Z"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FridgeBig;
