import React from "react";
import { IControlIcon } from "../../utils/types";

function WaterBig(props: IControlIcon) {
  return (
    <svg width="69" height="73" viewBox="0 0 69 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.8101 3.77637C27.8101 3.77637 3.72681 28.8918 3.72681 47.4876C3.72681 60.0453 14.9771 71.0376 27.8101 71.0376C40.6431 71.0376 51.8934 60.0453 51.8934 47.4876C51.8934 27.1716 27.8101 3.77637 27.8101 3.77637Z"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.6997 40.119C62.0181 39.1874 63.1689 38.0265 64.0934 36.6836C65.605 34.4879 66.4464 31.9005 66.5154 29.2356C66.5154 17.624 52.7535 4.25781 52.7535 4.25781C50.4892 6.69557 48.3814 9.27314 46.4424 11.975"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5104 47.9648C15.5864 51.0661 16.8524 54.0193 19.0459 56.2129C21.2395 58.4065 24.1927 59.6724 27.294 59.7485"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default WaterBig;
