import axios from "axios"

const config = {
    headers: {
        Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
    },
}

export const sendHomeAssistantRequest = async (path: string, data?: {}) => {
    try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/${path}`
        const body = {
            cubeHomeAssistantUrl: "http://192.168.88.100:8123",
            device: "tablet",
            homeAssistantBody: data || {},
        }
        const response = await axios.post(url, body)
        return response
    } catch (error) {
        throw error
    }
}
const sendLocalRequest = (path: string = "fan/turn_on", data?: {}) => {
  const url = `${process.env.REACT_APP_API_BASE_URL2}/${path}`;

  axios
    .post(url, data || {}, config)
    .then((res) => { return res; })
    .catch((err) => {
      console.log(err);
      return;
    });
}
