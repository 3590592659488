import React from "react";

function DataBox(props: any) {
  return (
    <svg width="57" height="66" viewBox="0 0 57 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.49168 51.5348C2.71176 49.9258 1 46.9573 1 43.7454V22.2535C1 19.0415 2.71188 16.0729 5.49195 14.464L23.9822 3.76313C26.7699 2.1498 30.2072 2.14919 32.9955 3.76151L51.5052 14.4648C54.2869 16.0732 56 19.0427 56 22.2559V43.743C56 46.9561 54.287 49.9255 51.5055 51.534L32.9958 62.2381C30.2073 63.8506 26.7697 63.85 23.9819 62.2365L5.49168 51.5348Z"
        fill="white"
        stroke={props.color ? props.color : "#F2552C"}
        strokeWidth="2"
      />
    </svg>
  );
}

export default DataBox;
