import React from "react";
import { IActiveIcon } from "../../utils/types";

function ActiveIcon(props: IActiveIcon) {
  const level: number = props.level > 100 ? 100 : 100 - props.level;

  return (
    <>
      <svg className="active-icon" width="202" height="232" viewBox="0 0 202 232" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id={`${props.id}-grad`} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset={`${level}%`} style={{ stopColor: "#e4dee6", stopOpacity: 1 }} />
            <stop offset="0%" style={{ stopColor: props.color, stopOpacity: 1 }} />
          </linearGradient>
        </defs>

        <path
          d="M5.70029 176.269C3.15107 174.783 1.58337 172.054 1.58337 169.103V63.0455C1.58337 60.0946 3.15117 57.3658 5.70053 55.8795L96.366 3.02274C98.9467 1.51815 102.138 1.51757 104.719 3.02123L195.463 55.8804C198.014 57.3663 199.583 60.0958 199.583 63.0478V169.1C199.583 172.052 198.014 174.782 195.464 176.268L104.719 229.131C102.138 230.635 98.9467 230.634 96.3658 229.129L5.70029 176.269Z"
          stroke={props.color}
          strokeWidth="3"
        />
        <path
          d="M17.4912 170.053C14.4835 168.308 12.6325 165.092 12.6325 161.614V70.3473C12.6325 66.8692 14.4836 63.654 17.4915 61.9077L95.8804 16.399C98.909 14.6408 102.647 14.6402 105.676 16.3973L184.134 61.9083C187.144 63.6541 188.997 66.8703 188.997 70.3496V161.612C188.997 165.091 187.144 168.308 184.134 170.053L105.677 215.568C102.648 217.325 98.9088 217.324 95.8801 215.566L17.4912 170.053Z"
          fill={`url(#${props.id}-grad)`}
        />
      </svg>
    </>
  );
}

export default ActiveIcon;
