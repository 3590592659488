import React from "react";
import { honeyCombControlsFridge, items, sideLength } from "../../utils/config";
import { IFridgeState, IFridgeController } from "../../utils/types";

import Honeycomb from "../honeycomb/honeycomb";
import Hexagon from "../honeycomb/hexagon";
import HexagonContent from "../honeycomb/hexagon-content";

const FridgeController = (props: IFridgeController) => {
  const activeRouterHandle = props.activeRouterHandle;
  const fridgeState: IFridgeState = props.fridgeState;
  const setFridgeIsOnStateHandle: Function = () => {
    props.setFridgeState("toggle");
  };
  const setFridgeTempHandle: Function = (temp: number) => {
    props.setFridgeState("temp", temp);
  };
  const setFridgeTempGoalHandle: Function = (temp: number) => {
    props.setFridgeState("temp-goal", temp);
  };

  return (
    <div className="honeycomb-container fridge">
      <Honeycomb
        className="honeycomb"
        columns={4}
        gap={0}
        size={sideLength}
        items={items}
        renderItem={(item: any, index: number) => {
          switch (honeyCombControlsFridge[index]) {
            case "fridge-page":
              return (
                <Hexagon className={`hexagon-item fridge-active`}>
                  <HexagonContent content={honeyCombControlsFridge[index]} />
                </Hexagon>
              );
            case "fridge-temperature-1":
              return (
                <Hexagon className={`hexagon-item ${fridgeState.isOn ? "fridge-active" : ""}`}>
                  <HexagonContent
                    isSelected={props.isFridgeSelected}
                    level={fridgeState.temperature1}
                    content={honeyCombControlsFridge[index]}
                    state={fridgeState}
                    setStateFunc={setFridgeIsOnStateHandle}
                    setStateValueFunc={setFridgeTempHandle}
                  />
                </Hexagon>
              );
              case "fridge-humidity-1":
                return (
                  <Hexagon className={`hexagon-item ${fridgeState.isOn ? "fridge-active" : ""}`}>
                    <HexagonContent
                      isSelected={props.isFridgeSelected}
                      level={fridgeState.humidity1}
                      content={honeyCombControlsFridge[index]}
                      state={fridgeState}
                      setStateFunc={setFridgeIsOnStateHandle}
                      setStateValueFunc={setFridgeTempHandle}
                    />
                  </Hexagon>
                );
            case "fridge-temperature-2":
              return (
                <Hexagon className={`hexagon-item ${fridgeState.isOn ? "fridge-active" : ""}`}>
                  <HexagonContent
                    level={fridgeState.temperature2}
                    content={honeyCombControlsFridge[index]}
                    state={fridgeState}
                    setStateFunc={setFridgeIsOnStateHandle}
                    setStateValueFunc={setFridgeTempGoalHandle}
                  />
                </Hexagon>
              );
              case "fridge-humidity-2":
                return (
                  <Hexagon className={`hexagon-item ${fridgeState.isOn ? "fridge-active" : ""}`}>
                    <HexagonContent
                      isSelected={props.isFridgeSelected}
                      level={fridgeState.humidity2}
                      content={honeyCombControlsFridge[index]}
                      state={fridgeState}
                      setStateFunc={setFridgeIsOnStateHandle}
                      setStateValueFunc={setFridgeTempHandle}
                    />
                  </Hexagon>
                );
            case "goback":
              return (
                <Hexagon className={`hexagon-item`}>
                  <HexagonContent activeRouterHandle={activeRouterHandle} content={honeyCombControlsFridge[index]} />
                </Hexagon>
              );
            default:
              return (
                <Hexagon className="hexagon-item">
                  <HexagonContent content={honeyCombControlsFridge[index]} />
                </Hexagon>
              );
          }
        }}
      />
    </div>
  );
};

export default FridgeController;
