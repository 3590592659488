import React from "react";
import { IControlIcon } from "../../utils/types";

function MusicBig(props: IControlIcon) {
  return (
    <svg width="68" height="69" viewBox="0 0 68 69" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1775_3454)">
        <path
          d="M13.0801 66.1327C15.1587 66.1327 17.1906 65.5163 18.9189 64.3615C20.6472 63.2067 21.9942 61.5653 22.7897 59.6449C23.5851 57.7246 23.7932 55.6114 23.3877 53.5728C22.9822 51.5341 21.9814 49.6615 20.5116 48.1917C19.0418 46.7219 17.169 45.721 15.1304 45.3155C13.0917 44.91 10.9787 45.1181 9.0583 45.9135C7.13793 46.709 5.49663 48.056 4.34182 49.7843C3.18702 51.5126 2.57056 53.5445 2.57056 55.6231C2.57056 58.4104 3.67775 61.0836 5.64868 63.0545C7.6196 65.0254 10.2928 66.1327 13.0801 66.1327Z"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55.1183 53.5211C57.1969 53.5211 59.2288 52.9047 60.9571 51.7499C62.6854 50.5951 64.0324 48.9537 64.8279 47.0334C65.6233 45.113 65.8314 42.9999 65.4259 40.9612C65.0204 38.9226 64.0196 37.0499 62.5498 35.5801C61.08 34.1104 59.2072 33.1094 57.1686 32.7039C55.1299 32.2984 53.0169 32.5065 51.0965 33.302C49.1761 34.0974 47.5348 35.4444 46.38 37.1727C45.2252 38.901 44.6088 40.9329 44.6088 43.0115C44.6088 45.7988 45.716 48.472 47.6869 50.4429C49.6578 52.4138 52.331 53.5211 55.1183 53.5211Z"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.5897 55.623V19.8064C23.5771 18.0575 24.1103 16.3481 25.115 14.9164C26.1197 13.4848 27.5458 12.4021 29.1948 11.8191L60.1629 3.24333C60.7868 3.06242 61.4446 3.03159 62.0827 3.15336C62.7207 3.27512 63.3209 3.54602 63.8343 3.94397C64.349 4.33412 64.7663 4.83835 65.0532 5.41704C65.3401 5.99573 65.489 6.6331 65.4879 7.27901V43.0115"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M23.5897 25.4115L65.628 12.8" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1775_3454">
          <rect width="67.2612" height="67.2612" fill="transparent" transform="translate(0.468628 0.973389)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MusicBig;
