import React from "react";
import { honeyCombControlsVentillator, items, sideLength } from "../../utils/config";
import { IVentillatorState, IVentillatorController } from "../../utils/types";

import Honeycomb from "../honeycomb/honeycomb";
import Hexagon from "../honeycomb/hexagon";
import HexagonContent from "../honeycomb/hexagon-content";

const VentillatorController = (props: IVentillatorController) => {
  const activeRouterHandle = props.activeRouterHandle;
  const ventillatorState: IVentillatorState = props.ventillatorState;
  const setFirstVentillatorStateHandle: Function = () => {
    props.setVentillatorState("1");
  };
  const setSecondVentillatorStateHandle: Function = () => {
    props.setVentillatorState("2");
  };

  const setFirstVentillatorFanSpeedHandle: Function = (speed: number) => {
    props.setVentillatorState("speed1", speed);
  };
  const setSecondVentillatorFanSpeedHandle: Function = (speed: number) => {
    props.setVentillatorState("speed2", speed);
  };

  return (
    <div className="honeycomb-container ventillator">
      <Honeycomb
        className="honeycomb"
        columns={4}
        gap={0}
        size={sideLength}
        items={items}
        renderItem={(item: any, index: number) => {
          switch (honeyCombControlsVentillator[index]) {
            case "ventillator-page":
              return (
                <Hexagon className={`hexagon-item ventillator-active`}>
                  <HexagonContent content={honeyCombControlsVentillator[index]} />
                </Hexagon>
              );
            case "ventillator-one":
              return (
                <Hexagon className={`hexagon-item ${ventillatorState.ventillatorOne.isOn ? "ventillator-active" : ""}`}>
                  <HexagonContent
                    isSelected={props.isVentillatorOneSelected}
                    level={100}
                    content={honeyCombControlsVentillator[index]}
                    state={ventillatorState}
                    setStateFunc={setFirstVentillatorStateHandle}
                    setStateValueFunc={setFirstVentillatorFanSpeedHandle}
                  />
                </Hexagon>
              );
            case "ventillator-two":
              return (
                <Hexagon className={`hexagon-item ${ventillatorState.ventillatorTwo.isOn ? "ventillator-active" : ""}`}>
                  <HexagonContent
                    isSelected={props.isVentillatorTwoSelected}
                    level={100}
                    content={honeyCombControlsVentillator[index]}
                    state={ventillatorState}
                    setStateFunc={setSecondVentillatorStateHandle}
                    setStateValueFunc={setSecondVentillatorFanSpeedHandle}
                  />
                </Hexagon>
              );
            case "goback":
              return (
                <Hexagon className={`hexagon-item`}>
                  <HexagonContent activeRouterHandle={activeRouterHandle} content={honeyCombControlsVentillator[index]} />
                </Hexagon>
              );
            default:
              return (
                <Hexagon className="hexagon-item">
                  <HexagonContent content={honeyCombControlsVentillator[index]} />
                </Hexagon>
              );
          }
        }}
      />
    </div>
  );
};

export default VentillatorController;
