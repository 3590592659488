export enum EHomeAssistantTopic {
    STATUS = "status",

    // grill

    // fan
    FAN_RIGHT_STATE = "fanRightState",
    FAN_RIGHT_IN_PERCENTAGE = "fanRightInPercentage",

    FAN_LEFT_STATE = "fanLeftState",
    FAN_LEFT_IN_PERCENTAGE = "fanLeftInPercentage",

    // fridge
    FRIDGE_1_TEMPERATURE = "fridge1Temperature",
    FRIDGE_2_TEMPERATURE = "fridge2Temperature",
    FRIDGE_1_HUMIDITY = "fridge1Humidity",
    FRIDGE_2_HUMIDITY = "fridge2Humidity",
    FRIDGE_IS_ON = "fridgeIsOn",

    // light
    LIGHT_BACK_STATE = "lightBackState",
    LIGHT_FRONT_STATE = "lightFrontState",
    LIGHT_OUTSIDE_STATE = "lightOutSideState",
    LIGHT_BACK_BRIGHTNESS = "lightBackBrightness",
    LIGHT_FRONT_BRIGHTNESS = "lightFrontBrightness",

    // water
    WATER_TANK_LEVEL = "waterTankLevel",
    WATER_PRESSURE_VALVE = "waterPressureValve",

    // stove
    STOVE_BIG_STATE = "stoveBigState",
    STOVE_SMALL_STATE = "stoveSmallState",

    // door
    DOOR_LEFT_WING = "doorLeftWing",
    DOOR_RIGHT_WING = "doorRightWing",

    // lock
    LOCK_STATE = "lockState",
    LOCK_STATE_TEXT = "lockStateText",
    LOCK_STATE_TEXT_LAST_CHANGED = "lockStateTextLastChanged",
}

export enum EHomeAssistantItems {
    BBQUBE_FAN_RIGHT = "fan.fan_topright",
    BBQUBE_FAN_LEFT = "fan.fan_top_left",
    LIGHT_FRONT = "light.shellydim_front",
    LIGHT_BACK = "light.shellydim_back",
    LIGHT_OUTSIDE = "switch.light_front",
    BUTTON_DRAWER_MOVE_IN = "button.drawer_move_in",
    BUTTON_DRAWER_MOVE_OUT = "button.drawer_move_out",
    BUTTON_DRAWER_RECOVERY = "button.drawer_recovery",
}

export enum EHomeAssistantEndpoints {
    BASE_PATH = "/home-assistant/",
    LIGHT = "light",
    SWITCH = "switch",
    FAN = "fan",
    WATER = "tank_water_level",
    STOVE_BIG = "gasburner_big",
    DRAWER_BUTTON = "button/press",
    GRILL = "fan",
    FRIDGE = "fan",
    LOCK = "lock",
    WEATHER = "weather/bbqube",
    SUN = "sun/sun",
}

export enum EHomeAssistantActions {
    TURN_ON = "turn_on",
    TURN_OFF = "turn_off",
    LOCK = "lock",
    UNLOCK = "unlock",
}
