import React from "react";

function WaterNormal() {
  return (
    <svg width="57" height="63" viewBox="0 0 57 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_54_114)">
        <path
          d="M4.9907 50.8476C1.9019 49.0599 0 45.7615 0 42.1927V20.7009C0 17.1319 1.9021 13.8335 4.991 12.0458L23.4813 1.34486C26.5787 -0.447637 30.398 -0.448338 33.496 1.34306L52.0058 12.0464C55.0965 13.8336 57 17.133 57 20.7033V42.1903C57 45.7604 55.0967 49.0598 52.0061 50.847L33.4964 61.5511C30.3981 63.3428 26.5786 63.3421 23.481 61.5493L4.9907 50.8476Z"
          fill="white"
        />
      </g>
      <path
        d="M25.9514 19.4473C25.9514 19.4473 17 28.7824 17 35.6941C17 40.3616 21.1816 44.4473 25.9514 44.4473C30.7212 44.4473 34.9028 40.3616 34.9028 35.6941C34.9028 28.143 25.9514 19.4473 25.9514 19.4473Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.1759 32.9552C38.6659 32.609 39.0937 32.1775 39.4373 31.6784C39.9992 30.8623 40.3119 29.9005 40.3375 28.9101C40.3375 24.5942 35.2224 19.6262 35.2224 19.6262C34.3808 20.5323 33.5974 21.4903 32.8767 22.4946"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3799 35.8713C21.4081 37.024 21.8787 38.1217 22.694 38.937C23.5093 39.7523 24.607 40.2228 25.7597 40.2511"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter id="filter0_i_54_114" x="0" y="0" width="57" height="62.8943" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_54_114" />
        </filter>
      </defs>
    </svg>
  );
}

export default WaterNormal;
