import { EHomeAssistantTopic } from "../interfaces/home-assistant"

export const apiUrl = "http://localhost:3000"

export const mqttConfig = {
    server: {
        host: process.env.REACT_APP_MTTQ_HOST, // host does NOT include port
        hostname: process.env.REACT_APP_MTTQ_HOST as string,
        protocol: "ws", // protocol?: 'wss' | 'ws' | 'mqtt' | 'mqtts' | 'tcp' | 'ssl' | 'wx' | 'wxs'
        path: "/ws",
        port: process.env.REACT_APP_MTTQ_PORT, // port is made into a number subsequently
        clientId: "mqttjs_" + Math.random().toString(16).substr(2, 8),
        reconnectPeriod: 10000,
        connectTimeout: 1000,
        username: process.env.REACT_APP_USERNAME,
        password: process.env.REACT_APP_PASSWORD,
    },
    mainTopics: {
        cube: "cube/",
        homeAssistantInCube : "/homeassistant"
    },
    cubeTopics: {
        [EHomeAssistantTopic.STATUS]: "/status",

        // grill

        // fan
        [EHomeAssistantTopic.FAN_RIGHT_STATE]: "/fan/fan_topright/state",
        [EHomeAssistantTopic.FAN_RIGHT_IN_PERCENTAGE]: "/fan/fan_topright/percentage",

        [EHomeAssistantTopic.FAN_LEFT_STATE]: "/fan/fan_top_left/state",
        [EHomeAssistantTopic.FAN_LEFT_IN_PERCENTAGE]: "/fan/fan_top_left/percentage",

        // fridge
        [EHomeAssistantTopic.FRIDGE_1_TEMPERATURE]: "/sensor/tuyatec_rh3052_fridge_1_temperature/state",
        [EHomeAssistantTopic.FRIDGE_2_TEMPERATURE]: "/sensor/tuyatec_rh3052_fridge_2_temperature/state",
        [EHomeAssistantTopic.FRIDGE_1_HUMIDITY]: "/sensor/tuyatec_rh3052_fridge_1_humidity/state",
        [EHomeAssistantTopic.FRIDGE_2_HUMIDITY]: "/sensor/tuyatec_rh3052_fridge_2_humidity/state",
        [EHomeAssistantTopic.FRIDGE_IS_ON]: "/switch/fridge1_engine/state",

        // light
        [EHomeAssistantTopic.LIGHT_BACK_STATE]: "/light/shellydim_back/state",
        [EHomeAssistantTopic.LIGHT_FRONT_STATE]: "/light/shellydim_front/state",
        [EHomeAssistantTopic.LIGHT_OUTSIDE_STATE]: "/switch/light_front/state",
        [EHomeAssistantTopic.LIGHT_BACK_BRIGHTNESS]: "/light/shellydim_back/brightness",
        [EHomeAssistantTopic.LIGHT_FRONT_BRIGHTNESS]: "/light/shellydim_front/brightness",

        // water
        [EHomeAssistantTopic.WATER_TANK_LEVEL]: "/sensor/tank_water_level/state",
        [EHomeAssistantTopic.WATER_PRESSURE_VALVE]: "/binary_sensor/pressure_valve/state",

        // stove
        [EHomeAssistantTopic.STOVE_BIG_STATE]: "/sensor/gasburner_big/state",
        [EHomeAssistantTopic.STOVE_SMALL_STATE]: "/sensor/gasburner_small/state",

        // door
        [EHomeAssistantTopic.DOOR_LEFT_WING]: "/binary_sensor/left_wing/state",
        [EHomeAssistantTopic.DOOR_RIGHT_WING]: "/binary_sensor/right_wing/state",

        // lock
        [EHomeAssistantTopic.LOCK_STATE]: "/lock/lock/state",
        [EHomeAssistantTopic.LOCK_STATE_TEXT]: "/sensor/state_text/state",
        [EHomeAssistantTopic.LOCK_STATE_TEXT_LAST_CHANGED]: "/sensor/lock_state/last_changed"
    },
}
