import React from "react";
import { IControlIcon } from "../../utils/types";

function LightBig(props: IControlIcon) {
  return (
    <svg width="69" height="71" viewBox="0 0 69 71" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34.052 46.8757C38.4863 46.8757 42.8211 45.5608 46.5081 43.0972C50.1951 40.6336 53.0688 37.132 54.7658 33.0352C56.4627 28.9384 56.9067 24.4304 56.0416 20.0813C55.1765 15.7321 53.0412 11.7373 49.9057 8.60176C46.7701 5.46621 42.7751 3.3308 38.426 2.4657C34.0768 1.60061 29.5689 2.04468 25.4721 3.74163C21.3753 5.43858 17.8737 8.31229 15.4101 11.9993C12.9465 15.6863 11.6316 20.021 11.6316 24.4553C11.6316 30.4016 13.9937 36.1042 18.1983 40.3089C22.403 44.5135 28.1057 46.8757 34.052 46.8757Z"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M25.0839 55.844H43.0202" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M25.0839 64.812H43.0202" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M34.052 69.2961V64.812" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M34.052 46.8758V35.6656L27.3259 28.9395" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M34.052 35.6656L40.7781 28.9395" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default LightBig;
