import React from "react";
import { honeyCombControlsWater, items, sideLength } from "../../utils/config";
import { IWaterState, IWaterController } from "../../utils/types";

import Honeycomb from "../honeycomb/honeycomb";
import Hexagon from "../honeycomb/hexagon";
import HexagonContent from "../honeycomb/hexagon-content";

const WaterController = (props: IWaterController) => {
  const activeRouterHandle = props.activeRouterHandle;
  const waterState: IWaterState = props.waterState;
  const setWaterTapStateHandle: Function = () => {
      props.setWaterState("toggle");
    };

  return (
    <div className="honeycomb-container water">
      <Honeycomb
        className="honeycomb"
        columns={4}
        gap={0}
        size={sideLength}
        items={items}
        renderItem={(item: any, index: number) => {
          switch (honeyCombControlsWater[index]) {
            case "water-page":
              return (
                <Hexagon className={`hexagon-item water-active`}>
                  <HexagonContent content={honeyCombControlsWater[index]} />
                </Hexagon>
              );
            case "water-level":
              return (
                <Hexagon className="hexagon-item water-active">
                  <HexagonContent
                    isSelected={props.isWaterSelected}
                    level={waterState.waterLevel}
                    content={honeyCombControlsWater[index]}
                    state={waterState}
                    setStateFunc={setWaterTapStateHandle}
                  />
                </Hexagon>
              );
            case "goback":
              return (
                <Hexagon className={`hexagon-item`}>
                  <HexagonContent activeRouterHandle={activeRouterHandle} content={honeyCombControlsWater[index]} />
                </Hexagon>
              );
            default:
              return (
                <Hexagon className="hexagon-item">
                  <HexagonContent content={honeyCombControlsWater[index]} />
                </Hexagon>
              );
          }
        }}
      />
    </div>
  );
};

export default WaterController;
