import React, { useState, useEffect, createContext } from "react"

import mqtt from "mqtt"
import { mqttConfig } from "./mqtt-config"
import { EHomeAssistantTopic } from "../interfaces/home-assistant"

type TCubeSensors = {
    [key in EHomeAssistantTopic]?: any
}

// set the default sensor values to undefined
let cubeSensorsDefaultValue: TCubeSensors = {}
Object.values(EHomeAssistantTopic).forEach((topic) => {
    cubeSensorsDefaultValue[topic] = undefined
})

interface ICubeContext {
    sensors: TCubeSensors
    // connectStatus: string
}

export const CubeContext = createContext<ICubeContext>({ sensors: cubeSensorsDefaultValue })

/**
 * Display data of sensors of one cube
 */
function MqttDataProvider(props: any) {
    // options from config
    const mqttOptions = mqttConfig.server

    // connection status
    const [connectStatus, setConnectStatus] = useState<string>("") // eslint-disable-line

    // mqtt client
    const [client, setClient] = useState<mqtt.MqttClient | null>(null)
    const [error, setError] = useState<string>("") // eslint-disable-line

    // state to store the data of the sensors
    const [cubeStates, setCubeStates] = useState(cubeSensorsDefaultValue)

    // if the client exists, get the data
    useEffect(() => {
        if (client) {
            client.on("connect", () => {
                setConnectStatus("Connected")
                console.log("connected!!")
            })

            client.on("error", (err) => {
                setConnectStatus("No connection")
                client.end()
            })

            client.on("reconnect", () => {
                setConnectStatus("Reconnection...")
            })

            client.on("message", (topic, message) => {
                // decode the message
                const payload = {
                    topic,
                    message: new TextDecoder().decode(message),
                }

                for (const [key, value] of Object.entries(mqttConfig.cubeTopics)) {
                    // set the proper data field of the cubeStates state object
                    if (`${mqttConfig.mainTopics.cube}${process.env.REACT_APP_CUBE_NAME}${mqttConfig.mainTopics.homeAssistantInCube}${value}` === topic) {
                        setCubeStates((prev) => {
                            
                            return { ...prev, [key]: payload.message }
                        })
                        break
                    }
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client])

    // if the client does not exist, connect again
    useEffect(() => {
        if (!client) {
            mqttConnect(mqttOptions as mqtt.IClientOptions)
        }
    }, []) // eslint-disable-line


        /**
     * connect to the mqtt
     * @param mqttOption
     */
         const mqttConnect = (mqttOption: mqtt.IClientOptions) => {
            let client: mqtt.MqttClient = mqtt.connect(mqttOptions.hostname, mqttOption)
            setClient(client)            
    
            // subscribe to changes in state values
            Object.values(mqttConfig.cubeTopics).forEach((topic) => {                
                client.subscribe(`${mqttConfig.mainTopics.cube}${process.env.REACT_APP_CUBE_NAME}${mqttConfig.mainTopics.homeAssistantInCube}${topic}`)
            })
        }
    /**
     * connect to the mqtt
     * @param mqttOption
     */
    // const mqttConnect = (mqttOption: mqtt.IClientOptions) => {
    //     let client: mqtt.MqttClient = mqtt.connect(mqttOptions.hostname, mqttOption)
    //     setClient(client)

    //     // subscribe to changes in state values
    //     Object.values(mqttConfig.cubeTopics).forEach((topic) => {
    //         client.subscribe(`${mqttConfig.mainTopics.cube}${process.env.REACT_APP_CUBE_NAME}${topic}`)
    //     })
    // }

    return <CubeContext.Provider value={{ sensors: cubeStates }}>{props.children}</CubeContext.Provider>
}

export default MqttDataProvider
