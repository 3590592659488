import React from "react";

function FridgeNormal() {
  return (
    <svg width="57" height="63" viewBox="0 0 57 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_54_115)">
        <path
          d="M4.9908 50.8476C1.902 49.0599 0 45.7615 0 42.1927V20.7008C0 17.1319 1.9021 13.8334 4.9911 12.0458L23.4813 1.3449C26.5787 -0.4477 30.398 -0.4483 33.4961 1.3431L52.0058 12.0464C55.0966 13.8336 57 17.133 57 20.7032V42.1903C57 45.7604 55.0967 49.0597 52.0061 50.847L33.4964 61.5511C30.3981 63.3428 26.5786 63.3421 23.481 61.5493L4.9908 50.8476Z"
          fill="white"
        />
      </g>
      <path d="M28.0107 15.4473V26.114" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33.3439 17.5806L28.0106 21.8473L22.6772 17.5806" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.1582 23.4541L23.3884 28.7875" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.6666 19.8987L19.6906 26.6543L13.3333 29.1432" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.1582 39.4542L23.3884 34.1208" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.3333 33.7654L19.6906 36.2543L18.6666 43.0099" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M28.0107 47.4471V36.7805" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.6772 45.3141L28.0106 41.0474L33.3439 45.3141" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M41.863 39.4542L32.6328 34.1208" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M37.3546 43.0099L36.3306 36.2543L42.6879 33.7654" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M41.863 23.4541L32.6328 28.7875" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M42.6879 29.1432L36.3306 26.6543L37.3546 19.8987" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M28.0106 26.114L23.3884 28.7738V34.121L28.0106 36.7807L32.6329 34.121V28.7738L28.0106 26.114Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter id="filter0_i_54_115" x="0" y="0" width="57" height="62.8943" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_54_115" />
        </filter>
      </defs>
    </svg>
  );
}

export default FridgeNormal;
