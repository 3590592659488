import React from "react";
import { honeyCombControlsLight, items, sideLength } from "../../utils/config";
import { ILightState, ILightController } from "../../utils/types";

import Honeycomb from "../honeycomb/honeycomb";
import Hexagon from "../honeycomb/hexagon";
import HexagonContent from "../honeycomb/hexagon-content";

const LightController = (props: ILightController) => {
  const activeRouterHandle = props.activeRouterHandle;
  const lightState: ILightState = props.lightState;
  const setMainLightStateHandle: Function = () => {
    props.setLightState("main");
  };
  const setSecondaryLightStateHandle: Function = () => {
    props.setLightState("secondary");
  };
  const setOutsideLightStateHandle: Function = () => {
    props.setLightState("outside");
  };

  const setMainLightDimHandle: Function = (value: number) => {
    props.setLightState("main-dim", value);
  };
  const setSecondaryLightDimHandle: Function = (value: number) => {
    props.setLightState("secondary-dim", value);
  };

  return (
    <div className="honeycomb-container light">
      <Honeycomb
        className="honeycomb"
        columns={4}
        gap={0}
        size={sideLength}
        items={items}
        renderItem={(item: any, index: number) => {
          switch (honeyCombControlsLight[index]) {
            case "light-page":
              return (
                <Hexagon className={`hexagon-item light-active`}>
                  <HexagonContent content={honeyCombControlsLight[index]} />
                </Hexagon>
              );
            case "light-main":
              return (
                <Hexagon className={`hexagon-item ${lightState.lightMain.isOn ? "light-active " : " "}${props.mainLightState ? " light-selected" : " "}`}>
                  <HexagonContent
                    isSelected={props.mainLightState}
                    level={lightState.lightMain.dim}
                    content={honeyCombControlsLight[index]}
                    state={lightState}
                    setStateFunc={setMainLightStateHandle}
                    setStateValueFunc={setMainLightDimHandle}
                  />
                </Hexagon>
              );
            case "light-secondary":
              return (
                <Hexagon className={`hexagon-item ${lightState.lightSecondary.isOn ? "light-active" : ""}`}>
                  <HexagonContent
                    isSelected={props.secondaryLightState}
                    level={lightState.lightSecondary.dim}
                    content={honeyCombControlsLight[index]}
                    state={lightState}
                    setStateFunc={setSecondaryLightStateHandle}
                    setStateValueFunc={setSecondaryLightDimHandle}
                  />
                </Hexagon>
              );
            case "light-outside":
              return (
                <Hexagon className={`hexagon-item ${lightState.lightOutside.isOn ? "light-active" : ""}`}>
                  <HexagonContent
                    isSelected={props.outsideLightState}
                    content={honeyCombControlsLight[index]}
                    state={lightState}
                    setStateFunc={setOutsideLightStateHandle}
                  />
                </Hexagon>
              );
            case "goback":
              return (
                <Hexagon className={`hexagon-item`}>
                  <HexagonContent activeRouterHandle={activeRouterHandle} content={honeyCombControlsLight[index]} />
                </Hexagon>
              );
            default:
              return (
                <Hexagon className="hexagon-item">
                  <HexagonContent content={honeyCombControlsLight[index]} />
                </Hexagon>
              );
          }
        }}
      />
    </div>
  );
};

export default LightController;
