import React from "react";

function LightNormal() {
  return (
    <svg width="57" height="66" viewBox="0 0 57 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.99076 52.4003C1.90196 50.6126 0 47.3142 0 43.7454V22.2535C0 18.6846 1.90208 15.3861 4.99105 13.5985L23.4813 2.89765C26.5787 1.10507 30.398 1.10438 33.4961 2.89585L52.0058 13.5991C55.0966 15.3863 57 18.6857 57 22.2559V43.743C57 47.3131 55.0967 50.6124 52.0061 52.3997L33.4964 63.1038C30.3981 64.8955 26.5786 64.8948 23.481 63.102L4.99076 52.4003Z"
        fill="white"
      />
      <path
        d="M27.7575 38.3333C29.8672 38.3333 31.9294 37.7077 33.6836 36.5357C35.4377 35.3636 36.8049 33.6977 37.6122 31.7486C38.4195 29.7995 38.6308 27.6548 38.2192 25.5857C37.8076 23.5166 36.7917 21.616 35.3 20.1242C33.8082 18.6325 31.9076 17.6165 29.8384 17.205C27.7693 16.7934 25.6246 17.0046 23.6755 17.812C21.7265 18.6193 20.0605 19.9865 18.8885 21.7406C17.7164 23.4948 17.0908 25.557 17.0908 27.6667C17.0908 30.4956 18.2146 33.2087 20.215 35.2091C22.2154 37.2095 24.9285 38.3333 27.7575 38.3333Z"
        fill="white"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M23.4907 42.6001H32.0241" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M23.4907 46.8667H32.0241" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M27.7576 49V46.8667" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M27.7574 39.5V33L24.5574 29.8" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M27.7576 33L30.9576 29.8" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default LightNormal;
