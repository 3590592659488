import React from "react";
import { IControlIcon } from "../../utils/types";

function StoveBig(props: IControlIcon) {
  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M52.0309 35.3236C61.1267 35.3236 68.5003 27.95 68.5003 18.8542C68.5003 9.75837 61.1267 2.38477 52.0309 2.38477C42.9351 2.38477 35.5615 9.75837 35.5615 18.8542C35.5615 27.95 42.9351 35.3236 52.0309 35.3236Z"
        fill="transparent"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7217 67.597C26.4427 67.597 33.5125 60.5272 33.5125 51.8062C33.5125 43.0852 26.4427 36.0154 17.7217 36.0154C9.00069 36.0154 1.93091 43.0852 1.93091 51.8062C1.93091 60.5272 9.00069 67.597 17.7217 67.597Z"
        fill="transparent"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6157 27.7544C21.6214 27.7544 27.3006 22.0752 27.3006 15.0696C27.3006 8.06396 21.6214 2.38477 14.6157 2.38477C7.6101 2.38477 1.93091 8.06396 1.93091 15.0696C1.93091 22.0752 7.6101 27.7544 14.6157 27.7544Z"
        fill="transparent"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.3743 66.9403C62.8034 66.9403 68.0152 61.7286 68.0152 55.2995C68.0152 48.8705 62.8034 43.6587 56.3743 43.6587C49.9453 43.6587 44.7335 48.8705 44.7335 55.2995C44.7335 61.7286 49.9453 66.9403 56.3743 66.9403Z"
        fill="transparent"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default StoveBig;
