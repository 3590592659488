import React from "react";

function StoveNormal() {
  return (
    <svg width="57" height="63" viewBox="0 0 57 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_54_116)">
        <path
          d="M4.9908 50.8476C1.902 49.0599 0 45.7615 0 42.1927V20.7008C0 17.1319 1.9021 13.8334 4.9911 12.0458L23.4813 1.3449C26.5787 -0.4477 30.398 -0.4483 33.4961 1.3431L52.0058 12.0464C55.0966 13.8336 57 17.133 57 20.7032V42.1903C57 45.7604 55.0967 49.0597 52.0061 50.847L33.4964 61.5511C30.3981 63.3428 26.5786 63.3421 23.481 61.5493L4.9908 50.8476Z"
          fill="white"
        />
      </g>
      <path
        d="M35.164 31.4904C38.9806 31.4904 42.0746 28.3964 42.0746 24.5798C42.0746 20.7632 38.9806 17.6692 35.164 17.6692C31.3474 17.6692 28.2534 20.7632 28.2534 24.5798C28.2534 28.3964 31.3474 31.4904 35.164 31.4904Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7677 45.0325C24.4271 45.0325 27.3935 42.066 27.3935 38.4066C27.3935 34.7473 24.4271 31.7808 20.7677 31.7808C17.1083 31.7808 14.1418 34.7473 14.1418 38.4066C14.1418 42.066 17.1083 45.0325 20.7677 45.0325Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4644 28.3144C22.404 28.3144 24.787 25.9314 24.787 22.9918C24.787 20.0522 22.404 17.6692 19.4644 17.6692C16.5248 17.6692 14.1418 20.0522 14.1418 22.9918C14.1418 25.9314 16.5248 28.3144 19.4644 28.3144Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.9863 44.7568C39.684 44.7568 41.8708 42.57 41.8708 39.8723C41.8708 37.1747 39.684 34.9878 36.9863 34.9878C34.2887 34.9878 32.1018 37.1747 32.1018 39.8723C32.1018 42.57 34.2887 44.7568 36.9863 44.7568Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter id="filter0_i_54_116" x="0" y="0" width="57" height="62.8943" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_54_116" />
        </filter>
      </defs>
    </svg>
  );
}

export default StoveNormal;
