import { range } from "lodash";
import { ILightState, IVentillatorState, IWaterState, IStoveState, IFridgeState, IGrillState } from "./types";

export enum colors {
  "orange" = "#F2552C",
  "purple" = "#4D0068",
  "blue" = "#3C63EF",
  "cyan" = "#1DB9CE",
  "bordeaux" = "#8B265D",
  "red" = "#F22C2C",
  "green" = "#65BE3B",
  "yellow" = "#ac9130",
  "white" = "#fff"
}

export const defaultPage: string = "home";

export const honeyCombControlsHome = [
  "", "", "light", "", "", "grill", "ventillator", "fridge", "", "", "stove", "", "", "", "water", "music"
];
export const honeyCombControlsLight = [
  "light-page", "", "", "", "", "light-main", "light-secondary", "light-outside", "", "", "", "", "", "", "", "goback"
];
export const honeyCombControlsVentillator = [
  "ventillator-page", "", "", "", "", "ventillator-two", "ventillator-one", "", "", "", "", "", "", "", "", "goback"
];
export const honeyCombControlsWater = [
  "water-page", "", "", "", "", "water-level", "", "", "", "", "", "", "", "", "", "goback"
];
export const honeyCombControlsStove = [
  "stove-page", "", "", "", "", "stove-temperature", "stove-gas", "", "", "", "", "", "", "", "", "goback"
];
export const honeyCombControlsFridge = [
  "fridge-page", "", "", "", "", "fridge-temperature-1", "fridge-humidity-1", "", "", "fridge-temperature-2", "fridge-humidity-2", "", "", "", "", "goback"
];
export const honeyCombControlsGrill = [
  "grill-page", "", "", "", "", "grill-temperature", "grill-goal", "", "", "drawer", "drawer-in", "", "", "", "drawer-recovery", "goback"
];


export const initialLightState: ILightState = {
  lightMain: {
    isOn: false,
    dim: 100,
  },
  lightSecondary: {
    isOn: false,
    dim: 20,
  },
  lightOutside: {
    isOn: false
    },
};


export const initialVentillatorState: IVentillatorState = {
  ventillatorOne: {
    isOn: false,
    speed: 50,
  },
  ventillatorTwo: {
    isOn: false,
    speed: 50,
  }

}

export const initialWaterState: IWaterState = {
  isRunning: false,
  waterLevel: 70
}

export const initialStoveState: IStoveState = {
  isOn: false,
  temperatureBig: 10,
  temperatureSmall: 11
}

export const initialFridgeState: IFridgeState = {
  isOn: false,
  temperature1: 8,
  humidity1: 40,
  temperature2: 4,
  humidity2: 40,
}

export const initialGrillState: IGrillState = {
  isOn: false,
  temperature: 150,
  temperatureGoal: 200,
  blow: false
}


export const items = range(16);
export const sideLength = 110;
export const maxTemp = 300;
export const maxTempForFridge = 25;
export const minTempForFridge = 0;