import React, { useState } from "react";
import MenuIcon from "./icons/menu-icon";
import Logo from "../assets/home-assist-logo.png";
import { defaultPage } from "../utils/config";

const Header = (props: any) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const navigatePage = (slug: string) => {
    props.activeRouterHandle(slug);
  };

  return (
    <header>
      <img className="logo" src={Logo} alt="" onClick={() => navigatePage(defaultPage)} />
      <div className="menu">
        <div className="dropdown" onClick={() => toggleMenu()}>
          <MenuIcon />
          <div
            onMouseLeave={() => {
              toggleMenu();
            }}
            className={`dropdown-content${isMenuOpen ? " open" : ""}`}>
            <button className={props.activeRouter === "home" ? "nav-active" : ""} onClick={() => navigatePage(defaultPage)}>
              Home Page
            </button>
            <button className={props.activeRouter === "light" ? "nav-active" : ""} onClick={() => navigatePage("light")}>
              Light Page
            </button>
            <button className={props.activeRouter === "ventillator" ? "nav-active" : ""} onClick={() => navigatePage("ventillator")}>
              Ventillator Page
            </button>
            <button className={props.activeRouter === "water" ? "nav-active" : ""} onClick={() => navigatePage("water")}>
              Water Page
            </button>
            <button className={props.activeRouter === "stove" ? "nav-active" : ""} onClick={() => navigatePage("stove")}>
              Stove Page
            </button>
            <button className={props.activeRouter === "fridge" ? "nav-active" : ""} onClick={() => navigatePage("fridge")}>
              Fridge Page
            </button>
            <button className={props.activeRouter === "grill" ? "nav-active" : ""} onClick={() => navigatePage("grill")}>
              Grill Page
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
