import React from "react";

function WaterSelected() {
  return (
    <svg width="57" height="63" viewBox="0 0 57 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.99285 13.7768L5.9929 13.7768L24.4831 3.07591L24.4831 3.07591C26.961 1.64186 30.0164 1.64134 32.495 3.07449L51.0046 13.7778L51.0047 13.7778C53.4773 15.2075 55 17.8471 55 20.7032V42.1903C55 45.0464 53.4774 47.6858 51.0049 49.1157L32.4952 59.8198C30.0165 61.2531 26.9609 61.2526 24.4828 59.8183L5.99265 49.1166L4.9908 50.8476L5.99264 49.1166C3.5216 47.6865 2 45.0477 2 42.1927V20.7008C2 17.8457 3.52168 15.2069 5.99285 13.7768Z"
        fill="white"
        stroke="#F82121"
        strokeWidth="4"
      />
      <path
        d="M25.9514 19.4473C25.9514 19.4473 17 28.7824 17 35.6941C17 40.3616 21.1816 44.4473 25.9514 44.4473C30.7212 44.4473 34.9028 40.3616 34.9028 35.6941C34.9028 28.143 25.9514 19.4473 25.9514 19.4473Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.1759 32.9552C38.6659 32.609 39.0937 32.1775 39.4373 31.6784C39.9992 30.8623 40.3119 29.9005 40.3375 28.9101C40.3375 24.5942 35.2224 19.6262 35.2224 19.6262C34.3808 20.5323 33.5974 21.4903 32.8767 22.4946"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3799 35.8713C21.4081 37.024 21.8787 38.1217 22.694 38.937C23.5093 39.7523 24.607 40.2228 25.7597 40.2511"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default WaterSelected;
