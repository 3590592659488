import React from "react";

function GrillActive() {
  return (
    <svg width="57" height="63" viewBox="0 0 57 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_54_185)">
        <path
          d="M4.9908 50.8476C1.902 49.0599 0 45.7615 0 42.1927V20.7008C0 17.1319 1.9021 13.8334 4.9911 12.0458L23.4813 1.3449C26.5787 -0.4477 30.398 -0.4483 33.4961 1.3431L52.0058 12.0464C55.0966 13.8336 57 17.133 57 20.7032V42.1903C57 45.7604 55.0967 49.0597 52.0061 50.847L33.4964 61.5511C30.3981 63.3428 26.5786 63.3421 23.481 61.5493L4.9908 50.8476Z"
          fill="#8B265D"
        />
      </g>
      <path
        d="M28.5689 28.6292C36.4095 28.6292 42.7655 27.3353 42.7655 25.7393C42.7655 24.1432 36.4095 22.8494 28.5689 22.8494C20.7284 22.8494 14.3723 24.1432 14.3723 25.7393C14.3723 27.3353 20.7284 28.6292 28.5689 28.6292Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M28.1851 42.6426C30.3042 42.6426 32.022 40.9247 32.022 38.8057C32.022 36.6866 30.3042 34.9688 28.1851 34.9688C26.0661 34.9688 24.3482 36.6866 24.3482 38.8057C24.3482 40.9247 26.0661 42.6426 28.1851 42.6426Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M14.3723 25.7283V32.5179C14.3723 40.3585 20.7284 46.7145 28.5689 46.7145C36.4095 46.7145 42.7655 40.3585 42.7655 32.5179V25.7283"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21.1495 16.5515L20.7312 17.1352C20.5641 17.3664 20.4878 17.6565 20.5179 17.9454C20.5481 18.2343 20.6824 18.4999 20.8931 18.6871C21.1048 18.8734 21.2405 19.1385 21.2719 19.4274C21.3033 19.7163 21.2281 20.007 21.0618 20.239L20.9471 20.3885"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.7296 16.5515L35.3112 17.1352C35.1442 17.3664 35.0679 17.6565 35.098 17.9454C35.1281 18.2343 35.2625 18.4999 35.4732 18.6871C35.6849 18.8734 35.8205 19.1385 35.8519 19.4274C35.8833 19.7163 35.8081 20.007 35.6419 20.239L35.5272 20.3885"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.0411 12.7146L27.8853 13.8555C27.4238 14.3073 27.2128 14.8744 27.2961 15.4391C27.3794 16.0038 27.7506 16.5229 28.3327 16.889C28.9176 17.253 29.2923 17.7712 29.3791 18.3359C29.4658 18.9007 29.258 19.4689 28.7987 19.9224L28.4818 20.2145"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter id="filter0_i_54_185" x="0" y="0" width="57" height="62.8943" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_54_185" />
        </filter>
      </defs>
    </svg>
  );
}

export default GrillActive;
