import React from "react";
import { honeyCombControlsGrill, items, sideLength } from "../../utils/config";
import { IGrillState, IGrillController } from "../../utils/types";

import Honeycomb from "../honeycomb/honeycomb";
import Hexagon from "../honeycomb/hexagon";
import HexagonContent from "../honeycomb/hexagon-content";
import { EHomeAssistantItems } from "../../interfaces/home-assistant";

const GrillController = (props: IGrillController) => {
  const activeRouterHandle = props.activeRouterHandle;
  const grillState: IGrillState = props.grillState;
  const setGrillBlowStateHandle: Function = () => {
    props.setGrillState("blow");
  };
  const setGrillTempStateHandle: Function = () => {
    props.setGrillState("toggle");
  };
  const setGrillTemperatureGoalHandle: Function = (temp: number) => {
    props.setGrillState("temp-goal", temp);
  };

  return (
    <div className="honeycomb-container grill">
      <Honeycomb
        className="honeycomb"
        columns={4}
        gap={0}
        size={sideLength}
        items={items}
        renderItem={(item: any, index: number) => {
          switch (honeyCombControlsGrill[index]) {
              case "grill-page":
                  return (
                      <Hexagon className={`hexagon-item grill-active`}>
                          <HexagonContent content={honeyCombControlsGrill[index]} />
                      </Hexagon>
                  )
              case "grill-temperature":
                  return (
                      <Hexagon className={`hexagon-item ${grillState.isOn ? "grill-active" : ""}`}>
                          <HexagonContent
                              isSelected={props.isGrillSelected}
                              level={grillState.temperature}
                              content={honeyCombControlsGrill[index]}
                              state={grillState}
                              setStateFunc={setGrillTempStateHandle}
                          />
                      </Hexagon>
                  )
              case "grill-goal":
                  return (
                      <Hexagon className={`hexagon-item ${grillState.isOn ? "grill-active" : ""}`}>
                          <HexagonContent
                              isSelected={props.isGrillSelected}
                              level={grillState.temperatureGoal}
                              content={honeyCombControlsGrill[index]}
                              state={grillState}
                              setStateFunc={setGrillTempStateHandle}
                              setStateValueFunc={setGrillTemperatureGoalHandle}
                          />
                      </Hexagon>
                  )
              case "grill-blow":
                  return (
                      <Hexagon className={`hexagon-item ${grillState.isOn && grillState.blow ? "grill-active" : ""}`}>
                          <HexagonContent level={100} content={honeyCombControlsGrill[index]} state={grillState} setStateFunc={setGrillBlowStateHandle} />
                      </Hexagon>
                  )
              case "drawer":
                  return (
                      // <Hexagon className={`hexagon-item ${props.drawerState ? "drawer-active" : ""}`}>
                      <Hexagon className={`hexagon-item ${"drawer-active"}`}>
                          <HexagonContent level={100} content={honeyCombControlsGrill[index]} state={props.drawerState} setStateFunc={() => props.setDrawerState(EHomeAssistantItems.BUTTON_DRAWER_MOVE_OUT)} />
                      </Hexagon>
                  )
              case "drawer-in":
                  return (
                      <Hexagon className={`hexagon-item ${"drawer-active"}`}>
                          <HexagonContent level={100} content={honeyCombControlsGrill[index]} state={props.drawerState} setStateFunc={() => props.setDrawerState(EHomeAssistantItems.BUTTON_DRAWER_MOVE_IN)} />
                      </Hexagon>
                  )
            case "drawer-recovery":
              return (
                  <Hexagon className={`hexagon-item`}>
                      <HexagonContent level={100} content={honeyCombControlsGrill[index]} state={props.drawerState} setStateFunc={() => props.setDrawerState(EHomeAssistantItems.BUTTON_DRAWER_RECOVERY)} />
                  </Hexagon>
              )
              case "goback":
                  return (
                      <Hexagon className={`hexagon-item`}>
                          <HexagonContent activeRouterHandle={activeRouterHandle} content={honeyCombControlsGrill[index]} />
                      </Hexagon>
                  )
              default:
                  return (
                      <Hexagon className="hexagon-item">
                          <HexagonContent content={honeyCombControlsGrill[index]} />
                      </Hexagon>
                  )
          }
        }}
      />
    </div>
  );
};

export default GrillController;
